const ArrowIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({ className, ...props }) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.75592 3.57739C9.43048 3.25195 8.90285 3.25195 8.57741 3.57739L2.74408 9.41072C2.66418 9.49062 2.6039 9.58271 2.56323 9.68099C2.52326 9.77738 2.50086 9.88289 2.50002 9.99354C2.49999 9.99783 2.49999 10.0021 2.50002 10.0064C2.50165 10.2175 2.583 10.4282 2.74408 10.5892L8.57741 16.4226C8.90285 16.748 9.43048 16.748 9.75592 16.4226C10.0814 16.0971 10.0814 15.5695 9.75592 15.2441L5.34518 10.8333H16.6667C17.1269 10.8333 17.5 10.4602 17.5 9.99998C17.5 9.53974 17.1269 9.16665 16.6667 9.16665H5.34518L9.75592 4.7559C10.0814 4.43047 10.0814 3.90283 9.75592 3.57739Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowIcon;
