interface Props {
  className?: string;
}

const ArrowCurvedUpIcon = ({ className }: Props) => (
  <svg className={className} width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.98518 0.60432C4.2037 0.4355 4.51771 0.475797 4.68653 0.694325L7.4376 4.25545C7.60642 4.47398 7.56613 4.78798 7.3476 4.9568C7.12907 5.12562 6.81507 5.08533 6.64625 4.8668L4.20085 1.70136L1.0354 4.14675C0.816875 4.31557 0.502869 4.27528 0.334049 4.05675C0.16523 3.83822 0.205524 3.52421 0.424053 3.35539L3.98518 0.60432ZM33.6512 19.9547C24.8253 23.9894 16.7203 24.3281 11.1075 21.0405C5.4595 17.7323 2.52032 10.8685 3.79492 0.936357L4.78679 1.06364C3.54299 10.7557 6.43007 17.1419 11.6129 20.1776C16.8309 23.234 24.5614 23.0106 33.2355 19.0453L33.6512 19.9547Z"
      fill="#124736"
    />
  </svg>
);

export default ArrowCurvedUpIcon;
