import React, { useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { PageEditForm } from './PageEditForm';
import { GET_SCREEN_BY_ID } from '../queries';
import { Loading } from '../../../components/Loading';
import { FeedbackBar } from '../../../components/FeedbackBar';
import { PageEditFormLayout } from './PageEditFormLayout';
import { usePageActions } from './hooks/usePageActions';

interface RouteParams {
  screen_id: string;
}

export const NewPage = () => {
  const { screen_id } = useParams<RouteParams>();
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_SCREEN_BY_ID, {
    variables: { id: screen_id }
  });

  const { addPage, handleSavePage } = usePageActions(data?.screen);
  const newPage = useMemo(() => addPage(), [addPage]);

  if (loading)
    return (
      <PageEditFormLayout isNew>
        <Loading />
      </PageEditFormLayout>
    );
  if (error) return <FeedbackBar message={error.message} />;

  const screen = data?.screen;
  if (!screen) return null;

  const handleSave = async (page: any) => {
    await handleSavePage(page);
    history.push(`/screens/${screen_id}/edit`);
  };

  return (
    <PageEditFormLayout isNew>
      <PageEditForm
        screen={screen}
        page={newPage}
        onSave={handleSave}
        onCancel={() => history.push(`/screens/${screen_id}/edit`)}
        onDelete={() => history.push(`/screens/${screen_id}/edit`)}
      />
    </PageEditFormLayout>
  );
};
