import { useTranslation } from 'react-i18next';
import DarkColor from '../../../../../images/ph-dark.png';
import LightColor from '../../../../../images/ph-light.png';

export const ColorsHelper = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-2xl font-semmibold text-dark-grey leading-[normal]">{t('PopupHelpers.Colors')}</h2>
      <p className="text-base text-dark-grey leading-[normal] -mt-4">{t('PopupHelpers.ColorsSubtitle') as string}</p>
      <div className="flex gap-12 flex-wrap justify-center">
        <img src={DarkColor} alt="" />
        <img src={LightColor} alt="" />
      </div>
    </div>
  );
};
