const planTitleAndDescriptionMap = {
  grandfathered: {
    title: [{ text: 'Plans.BasicGrandfathered', color: '' }],
    description: { text: 'Plans.BasicDescriptionGrandfathered', color: 'text-text' },
    buttonText: 'Account.ButtonUpgrade'
  },
  basic: {
    title: [{ text: 'Plans.Basic', color: '' }],
    description: { text: 'Plans.BasicDescription', color: 'text-text' },
    buttonText: 'Account.ButtonUpgrade'
  },
  premium: {
    title: [{ text: 'Plans.Premium', color: '' }],
    description: { text: 'Plans.PremiumDescription', color: 'text-text' },
    buttonText: 'Account.ButtonEditPlan'
  },
  grandfatheredFreeTrial: {
    title: [{ text: 'Plans.BasicGrandfathered', color: '' }],
    description: { text: 'Plans.BasicDescriptionGrandfatheredFreeTrial', color: 'text-text' },
    buttonText: 'Account.ButtonStartFreeTrial'
  },
  basicFreeTrial: {
    title: [{ text: 'Plans.Basic', color: '' }],
    description: { text: 'Plans.BasicDescriptionFreeTrial', color: 'text-text' },
    buttonText: 'Account.ButtonStartFreeTrial'
  },
  premiumExpiring: {
    title: [
      { text: 'Plans.Premium', color: '' },
      { text: 'Plans.Expiring', color: 'text-dark-orange' }
    ],
    description: { text: 'Plans.PremiumDescriptionExpiring', color: 'text-text' },
    buttonText: 'Account.ButtonEditPlan'
  },
  pendingPayment: {
    title: [{ text: 'Plans.Basic', color: '' }],
    description: { text: 'Plans.PremiumDescriptionExpired', color: 'text-dark-orange' },
    buttonText: 'Account.ButtonEditPlan'
  }
};

export const getTitleAndDescription = (
  isPremium: boolean,
  isGrandfathered: boolean,
  canTrial: boolean,
  isPendingPayment: boolean,
  isExpiring: boolean
) => {
  if (isPendingPayment) return planTitleAndDescriptionMap.pendingPayment;
  if (isExpiring) return planTitleAndDescriptionMap.premiumExpiring;

  const plan = isPremium ? 'premium' : isGrandfathered ? 'grandfathered' : 'basic';
  const trial = !isPremium && canTrial ? 'FreeTrial' : '';

  const key = (plan + trial) as keyof typeof planTitleAndDescriptionMap;
  return planTitleAndDescriptionMap[key];
};
