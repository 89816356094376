import React, { useContext, useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import { Add } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import clsx from 'clsx';

import { SessionContext } from '../../contexts/session';

import { Layout } from '../../components/Layout';
import { Loading } from '../../components/Loading';
import { ScreenListItem } from './ScreenListItem';
import { FeedbackBar } from '../../components/FeedbackBar';
import PremiumFeatureDialog from '../../components/PremiumFeatureDialog';

import { GET_SCREENS } from './queries';

import EmptyScreenListImage from '../../images/screens.svg';
import CustomLink from '../../components/CustomLink';
import { Button } from '../../components/shared/Button';
import { Alert } from '../../components/shared/Alert';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },

  emptyListContainer: {
    padding: '70px 30px',
    textAlign: 'center',

    '& img': {
      width: 140
    },

    '& p': {
      padding: '20px 0'
    }
  }
}));

export const Screens = withRouter(({ history }) => {
  const { t } = useTranslation();
  let session = useContext(SessionContext);
  const { currentLocation, premium } = session;

  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);

  const classes = useStyles();

  const { data, loading, error, startPolling, stopPolling } = useQuery(GET_SCREENS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network'
  });

  let screens = data?.screens || [];
  const isEmpty = screens.length === 0 && !loading;

  const handleAddNewScreen = () => {
    if (premium || screens.length === 0) {
      // TODO: protect this route
      history.push(`/screens/new`);
    } else {
      setShowPremiumFeatureDialog(true);
    }
  };

  const goToNewScreen = () => history.push(`/screens/new`);

  const edit = (screen?: any) => {
    if (screen) return history.push(`/screens/${screen._id}/edit`);

    goToNewScreen();
  };

  useEffect(() => {
    startPolling(30 * 1000);
    return stopPolling;
  }, [startPolling, stopPolling]);

  return (
    <Layout title={t('Screens.Screens')} className="!pt-0 font-public-sans">
      {!isEmpty && (
        <Button variant="primary" className="rounded-full m-3" onClick={handleAddNewScreen}>
          <Add /> {t('Screens.NewScreen')}
        </Button>
      )}

      <FeedbackBar message={error} />

      {loading && screens.length === 0 && <Loading style={{ padding: 40 }} />}

      {isEmpty && (
        <div className="w-full flex flex-col items-center">
          <div className={clsx(classes.emptyListContainer, 'flex max-w-xl flex-col items-center')}>
            <p className="text-xl font-bold">{t('Screens.NoScreensYet')}</p>
            <img alt="No Screens" src={EmptyScreenListImage} />
            <p className="text-md">
              <Trans
                i18nKey="Screens.ScreensDisplayedFull"
                components={{
                  customLink: (
                    <CustomLink href="/taps">
                      <span className="sr-only">tap list link</span>
                    </CustomLink>
                  )
                }}
              />
            </p>
            <Button variant="primary" className="mt-3" onClick={goToNewScreen}>
              {t('Screens.AddFirstScreen')}
            </Button>
          </div>
        </div>
      )}

      {screens.length > 0 && (
        <>
          <Alert severity="info" variant="standard">
            <p className="flex items-center justify-center">{t('Screens.ScreenReady')}</p>
          </Alert>

          {screens.map((screen: any, index: number) => (
            <ScreenListItem key={screen._id} screen={screen} onClick={() => edit(screen)} index={index} />
          ))}
        </>
      )}
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureDescription={t('PremiumFeatureDialog.Features.UnlimitedScreensDescription')}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </Layout>
  );
});
