import { useState } from 'react';
import { HelperPopupType } from './types';

export const useHelperPopups = () => {
  const [activePopup, setActivePopup] = useState<HelperPopupType | null>(null);

  const openPopup = (type: HelperPopupType) => setActivePopup(type);
  const closePopup = () => setActivePopup(null);

  const openSizeHelper = () => openPopup(HelperPopupType.Size);
  const openColorsHelper = () => openPopup(HelperPopupType.Colors);
  const openOptionsHelper = () => openPopup(HelperPopupType.Options);

  return {
    activePopup,
    closePopup,
    openSizeHelper,
    openColorsHelper,
    openOptionsHelper
  };
};
