import React from 'react';
import { Redirect } from 'react-router-dom';

import { Login } from './screens/auth/login';
import { Logout } from './screens/auth/logout';
import { SignUp } from './screens/signup';
import { ForgotPassword } from './screens/auth/forgotpassword';
import { ResetPassword } from './screens/auth/resetpassword';
import { AccountSettings } from './screens/settings/account';
import AccountSubscriptions from './screens/settings/subscriptions';

import { Screens } from './screens/screens';
import { EditScreen } from './screens/screens/edit';
import { NewScreen } from './screens/screens/new';
import { Preview } from './screens/screens/preview';
import { PageEdit } from './screens/screens/pages/edit';
import { NewPage } from './screens/screens/pages/new';

import { Taps } from './screens/taps';
import { EditTap } from './screens/taps/edit';
import { NewTap } from './screens/taps/new';
import { PrintTaps } from './screens/taps/print';
import { PrintQr } from './screens/taps/qr';
import { CopyTaps } from './screens/taps/copy';

import { GoogleCast } from './screens/wall/googlecast';
import { DirectDisplay } from './screens/wall/direct';
import { ViewScreen } from './screens/wall/screen';

import { Link } from './screens/devices/link';
import { EmbededTapList } from './screens/wall/embed';
import { SignUpMember } from './screens/signup-member';

const routes = [
  { path: '/', exact: true, component: () => <Redirect to="/login" /> },
  { path: '/login/:token?/:tokenUser?', component: Login },
  { path: '/signup/:email?', component: SignUp },
  { path: '/signup-member', component: SignUpMember },
  { path: '/logout', component: Logout },
  { path: '/forgotpassword', component: ForgotPassword },
  { path: '/resetpassword/:token', component: ResetPassword },

  { path: '/screens', component: Screens, private: true, exact: true },
  { path: '/screens/new', component: NewScreen, private: true, exact: true },
  {
    path: '/screens/:screen_id/edit',
    component: EditScreen,
    private: true,
    exact: true
  },
  {
    path: '/screens/:screen_id/preview',
    component: Preview,
    private: true,
    exact: true
  },
  {
    path: '/screens/:screen_id/pages/new',
    component: NewPage,
    private: true,
    exact: true
  },
  {
    path: '/screens/:screen_id/pages/:page_key/edit',
    component: PageEdit,
    private: true,
    exact: true
  },

  { path: '/taps', component: Taps, private: true, exact: true },
  { path: '/taps/new', component: NewTap, private: true, exact: true },
  { path: '/taps/copy', component: CopyTaps, private: true, exact: true },
  {
    path: '/taps/print',
    component: PrintTaps,
    private: true,
    exact: true
  },
  { path: '/taps/qr', component: PrintQr, private: true },
  {
    path: '/taps/:tap_id/edit',
    component: EditTap,
    private: true,
    exact: true
  },

  { path: '/link', component: Link, private: true, exact: true },

  { path: '/account', component: AccountSettings, private: true, exact: true },
  {
    path: '/account/subscription',
    component: AccountSubscriptions,
    private: true,
    exact: true
  },

  // device access
  { path: '/wall/googlecast/:deviceId?', component: GoogleCast, exact: true },
  { path: '/wall/direct/:deviceId?', component: DirectDisplay, exact: true },
  { path: '/wall/screen/:screen_id', component: ViewScreen, exact: true },

  // external
  {
    path: '/embed/:locationId/:settings?',
    component: EmbededTapList,
    exact: true
  }
];

export default routes;
