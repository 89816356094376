import React, { useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { spreadMutations } from '../util/graphql';
import { useMutation } from 'react-apollo';
import { CREATE_LOCATION, DELETE_LOCATION, EDIT_LOCATION } from '../screens/settings/mutations';
import { CurrencyDropdown } from './CurrencyDropdown';
import { VolumeUnitDropdown } from './VolumeUnitDropdown';
import { DeleteDialog } from './DeleteDialog';
import { Modal } from './shared/Modal';
import { Input } from './shared/Input';
import ArrowIcon from '../images/icon-arrow';
import { Button } from './shared/Button';
import { Alert } from './shared/Alert';
import ExclamationIcon from '../images/icon-exclamation';

interface EditLocationDialogProps {
  location: any;
  account: any;
  subDetail: any;
  onSuccess: (newLocation?: any) => void;
  onCancel: () => void;
  canDelete: boolean;
}

export const EditLocationDialog = ({ location, account, subDetail, onSuccess, onCancel, canDelete }: EditLocationDialogProps) => {
  const { t } = useTranslation();

  const [name, setName] = useState(location?.name);
  const [currency, setCurrency] = useState(location?.servingSettings?.currency || 'USD');
  const [volumeUnit, setVolumeUnit] = useState(location?.servingSettings?.volumeUnit || 'oz');

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [validating, setValidating] = useState(false);
  const [isDelLoading, toggleDelLoading] = useReducer((state) => !state, false);
  const [isDoneLoading, toggleDoneLoading] = useReducer((state) => !state, false);

  const [mutationResults] = spreadMutations([useMutation(CREATE_LOCATION), useMutation(EDIT_LOCATION), useMutation(DELETE_LOCATION)]);

  const [createLocation, editLocation, deleteLocation] = mutationResults as any[];

  const handleSave = async () => {
    toggleDoneLoading();
    setValidating(true);

    let locationData = {
      name,
      servingSettings: {
        currency,
        volumeUnit
      },
      shortName: '',
      active: true
    };

    try {
      if (name) {
        let newLocation = null;
        if (!location?._id) {
          const { data } = await createLocation({
            variables: {
              record: { ...locationData, account: account._id }
            }
          });
          newLocation = data?.locationCreateOne?.record;
        } else {
          await editLocation({
            variables: {
              record: { ...locationData, _id: location?._id }
            }
          });
        }
        onSuccess(newLocation);
      }
    } catch (error) {
      console.error('Failed to save location:', error);
    } finally {
      toggleDoneLoading();
    }
  };

  const onDelete = async () => {
    toggleDelLoading();
    setShowDeleteDialog(false);
    try {
      await deleteLocation({
        variables: {
          id: location._id
        }
      });
      onSuccess();
    } catch (error) {
      console.error('Failed to delete location:', error);
    } finally {
      toggleDelLoading();
    }
  };

  const handleDelete = () => {
    setShowDeleteDialog(true);
  };
  const onCancelDelete = () => {
    setShowDeleteDialog(false);
  };

  const formatNewLocationDesc = (text: string) => {
    const subPrice = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: subDetail?.currency || 'USD'
    }).format(subDetail?.unit_amount / 100);
    return text
      .replace('{sub_price}', subPrice)
      .replace('{sub_interval}', subDetail?.interval)
      .replace('{sub_period}', subDetail?.interval === 'year' ? 'Annual' : 'Monthly');
  };

  return (
    <Modal open={location !== null} onClose={onCancel}>
      <div className="p-6 max-w-[700px]">
        <div className="flex items-center mb-6">
          <button onClick={onCancel} className="p-2 hover:bg-gray-100 rounded-full">
            <ArrowIcon className="w-6 h-6" />
          </button>
          <h1 className="flex-1 text-center text-md font-bold uppercase text-dark-grey">
            {!location?._id ? t('LocationCard.NewLocationTitle') : t('LocationCard.EditLocationTitle')}
          </h1>
        </div>

        <div className="space-y-6 mb-24">
          <div>
            <h2 className="text-dark-grey font-bold mb-4 uppercase">{t('LocationCard.Basics')}</h2>
            <div className="space-y-2">
              <Input type="text" error={validating && !name} label={t('LocationCard.LocationName')} value={name} onChange={setName} />
              {validating && !name && <p className="text-red-500 text-sm">*{t('LocationCard.LocationNameError')}</p>}
            </div>
          </div>

          <div className="space-y-4">
            <h2 className="text-dark-grey font-bold uppercase">{t('LocationCard.DefaultSettings')}</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <CurrencyDropdown currency={currency} setCurrency={setCurrency} label={t('LocationCard.DefaultCurrency')} />
              <VolumeUnitDropdown label={t('LocationCard.DefaultVolumeUnit')} volumeUnit={volumeUnit} setVolumeUnit={setVolumeUnit} />
            </div>
          </div>

          {!location?._id && (
            <Alert
              icon={<ExclamationIcon className="text-hover-green" />}
              severity="info"
              className="text-dark-green border-green text-lg leading-[21.15px] font-medium">
              {formatNewLocationDesc(t('LocationCard.NewLocationPriceDesc'))}
            </Alert>
          )}
        </div>
        <div className="absolute bottom-0 left-0 w-full bg-white p-6 flex justify-end gap-x-2 z-10 border-t border-accent-grey">
          <Button
            variant={!location?._id ? 'secondary' : 'destructive'}
            onClick={!location?._id ? onCancel : handleDelete}
            disabled={(location?._id && !canDelete) || isDelLoading}>
            {isDelLoading ? (
              <span className="inline-block w-5 h-5 border-2 border-t-transparent rounded-full animate-spin" />
            ) : !location?._id ? (
              t('LocationCard.CancelLocationButton')
            ) : (
              t('LocationCard.DeleteLocationButton')
            )}
          </Button>

          <Button onClick={handleSave} disabled={isDoneLoading}>
            {isDoneLoading ? (
              <span className="inline-block w-5 h-5 border-2 border-t-transparent rounded-full animate-spin" />
            ) : !location?._id ? (
              t('LocationCard.NewLocationButton')
            ) : (
              t('LocationCard.EditLocationButton')
            )}
          </Button>
        </div>
      </div>

      {location._id && (
        <DeleteDialog
          show={showDeleteDialog}
          onCancel={onCancelDelete}
          title={t('LocationCard.DeleteDialogTitle')}
          description={t('LocationCard.DeleteDialogDesc')}
          onDelete={onDelete}
        />
      )}
    </Modal>
  );
};
