import { Fragment, useEffect } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../utils';

import moment from 'moment-timezone';
import { EDIT_SUBSCRIPTION } from '../mutations';
import { Button } from '../../../components/shared/Button';
import { getTitleAndDescription } from './utils';

export const useAccountPlan = (account: any, subsDetailData: any) => {
  const { t, i18n } = useTranslation();

  const [editSubscription, { loading, data }] = useMutation(EDIT_SUBSCRIPTION);

  useEffect(() => {
    if (data?.editSubscription?.stripePortalUrl) {
      window.location.href = data?.editSubscription?.stripePortalUrl;
    }
  }, [data]);

  const canTrial = !!account?.elegibleForFreeTrial;
  const pendingPaymentSince = account?.pendingSubscriptionPayment;
  const isPendingPayment = !!pendingPaymentSince;
  const isGrandfathered = !!subsDetailData?.subscription?.grandfathered;
  const isPremium = account?.plan === 'premium' && !isGrandfathered;
  const isBasic = !isPremium && !isGrandfathered;
  const cancelingAt = account?.datePlanCancels;
  const cancelingSoon = isPremium && !!cancelingAt;

  const { title, description, buttonText } = getTitleAndDescription(isPremium, isGrandfathered, canTrial, isPendingPayment, cancelingSoon);

  const finalDescription = isPendingPayment
    ? { ...description, text: t(description.text, { date: formatDate(pendingPaymentSince, i18n.language) }) }
    : cancelingSoon
    ? {
        ...description,
        text: t(description.text, {
          date: formatDate(cancelingAt, i18n.language, moment(cancelingAt).isAfter(moment().add(1, 'month')))
        })
      }
    : { ...description, text: t(description.text) };

  const finalTitle = title.map((titleItem) => ({
    ...titleItem,
    text: t(titleItem.text)
  }));

  const handleCreateSubscription = async () => {
    if (isPremium || isPendingPayment) {
      await editSubscription();
    } else {
      window.location.pathname = '/account/subscription';
    }
  };

  return {
    title: finalTitle,
    description: finalDescription,
    buttonText: t(buttonText),
    handleCreateSubscription,
    loading,
    isBasic,
    isPendingPayment,
    cancelingSoon,
    isPremium,
    isGrandfathered,
    canTrial,
    cancelingAt
  };
};

export const PlanCard = (accountPlanData: ReturnType<typeof useAccountPlan>) => {
  const { title, description, buttonText, handleCreateSubscription, loading } = accountPlanData;

  return (
    <div className="flex flex-col gap-2 max-w-[500px]">
      <h3 className="text-xl font-bold">
        {title.map((titleItem, index) => (
          <Fragment key={index}>
            {index > 0 && ' - '}
            {titleItem.color ? <span className={titleItem.color}>{titleItem.text}</span> : titleItem.text}
          </Fragment>
        ))}
      </h3>
      <p className={`mb-2 text-base font-normal ${description.color}`}>{description.text}</p>
      <Button variant="primary" size="md" className="w-fit" onClick={handleCreateSubscription} disabled={loading}>
        {buttonText}
      </Button>
    </div>
  );
};
