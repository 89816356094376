import { useTranslation } from 'react-i18next';
import WithStripes from '../../../../../images/ph-stripes-on.png';
import WithoutStripes from '../../../../../images/ph-stripes-off.png';

export const OptionsHelper = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-6 max-w-[358px]">
      <h2 className="text-2xl font-semmibold text-dark-grey leading-[normal]">{t('PopupHelpers.Stripes')}</h2>
      <p className="text-base text-dark-grey leading-[normal] -mt-4">{t('PopupHelpers.StripesSubtitle') as string}</p>
      <div className="flex gap-6 flex-wrap justify-center">
        <div className="flex flex-col gap-2 text-xl text-dark-grey font-normal leading-[normal] items-center max-w-[167px]">
          {t('PopupHelpers.WithStripes') as string}
          <img src={WithStripes} alt="" />
        </div>
        <div className="flex flex-col gap-2 text-xl text-dark-grey font-normal leading-[normal] items-center max-w-[167px]">
          {t('PopupHelpers.WithoutStripes') as string}
          <img src={WithoutStripes} alt="" />
        </div>
      </div>
    </div>
  );
};
