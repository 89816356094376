import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

interface Screen {
  _id: string;
  name: string;
  pages: any[];
  device?: {
    lastHeartbeat?: string;
  };
}

interface ScreenListItemProps {
  screen: Screen;
  onClick: () => void;
  index: number;
}

export const ScreenListItem: React.FC<ScreenListItemProps> = ({ screen, onClick, index }) => {
  const { t, i18n } = useTranslation();
  const [lastSeen, setLastSeen] = useState('Never');
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const updateLastSeen = () => {
      if (screen.device?.lastHeartbeat) {
        let heartbeat = moment(screen.device.lastHeartbeat);
        heartbeat.locale(i18n.language);
        setLastSeen(heartbeat.fromNow());
        // assume if we haven't heard from the device in 5 minutes, we're not connected anymore
        if (moment().diff(heartbeat, 'minutes') <= 5) {
          setConnected(true);
        } else {
          setConnected(false);
        }
      }
    };

    updateLastSeen();

    const MINUTE = 1000 * 60;
    const interval = setInterval(() => updateLastSeen(), MINUTE * 6);

    return () => clearInterval(interval);
  }, [screen.device, i18n.language]);

  return (
    <div className="grid grid-cols-[72px_1fr_auto] border-b border-accent-grey">
      {/* Left number column */}
      <div className="flex justify-center py-6">
        <span className="inline-block border border-accent-grey rounded-full h-10 w-10 leading-[38px] text-center text-sm">
          {index + 1}
        </span>
      </div>

      {/* Main content column */}
      <div className="py-6 cursor-pointer" onClick={onClick}>
        <div
          className={`font-bold text-2xl mb-1 ${
            screen.pages.length === 1 ? 'text-dark-green' : ''
          } font-bold text-[1.25rem] leading-snug mb-1 transition-colors duration-200 hover:text-orange`}>
          {screen.name}
        </div>
        <div className="text-lg leading-tight">
          {screen.pages.length} {screen.pages.length === 1 ? t('ScreenListItem.Pages') : t('ScreenListItem.Pages_plural')}
        </div>
        <div className="flex items-center mt-2">
          {!connected && (
            <div className="flex items-center">
              <span className={`inline-block h-2.5 w-2.5 rounded-full bg-orange mr-1`}></span>
              <span className={`font-semibold text-orange`}>{t('ScreenListItem.Disconnected')}</span>
            </div>
          )}
          {connected && (
            <div className="flex items-center">
              <span className={`inline-block h-2.5 w-2.5 rounded-full bg-green mr-1`}></span>
              <span className={`font-semibold text-dark-green`}>{t('ScreenListItem.Connected')}</span>
            </div>
          )}
        </div>

        {/* View for medium screens and below */}
        <div className="md:hidden col-span-2 mt-5 md:px-6">
          <div className="text-sm text-light-blackish text-left">
            {t('ScreenListItem.LastSeen')} {lastSeen || t('ScreenListItem.Never')}
          </div>
        </div>
      </div>

      {/* Right column with last seen - hidden on medium screens and below */}
      <div className="hidden md:block py-6 pr-6 text-right">
        <div className="text-sm text-light-blackish">
          {t('ScreenListItem.LastSeen')} {lastSeen || t('ScreenListItem.Never')}
        </div>
      </div>
    </div>
  );
};
