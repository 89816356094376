import React, { useImperativeHandle } from 'react';
import { IconButton, TextField, Paper } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { getVesselLabel } from '../../../util/lang';
import { CURRENCIES_ARRAY, getCurrencySign } from '../../../util/currencies';
import { DeleteDialog } from '../../../components/DeleteDialog';
import { Modal } from '../../../components/shared/Modal';
import { Button } from '../../../components/shared/Button';
import { Input } from '../../../components/shared/Input';
import { DisplayVessel, Serving } from '../../../types';
import { VOLUME_UNITS_ARRAY } from '../../../util/volumeUnits';
import { useEditServings } from '../../../hooks/servings/useEditServings';

const filter = createFilterOptions();

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid rgba(0, 0, 0, 0.12)'
  }
}));

interface Props {
  serving: Serving | Partial<Serving> | null;
  onCancel: () => void;
  onSave: (serving: Serving) => void;
}

interface FromProps {
  serving: Serving;
  onSave: (serving: Serving) => void;
  onCancel?: () => void;
  showActions?: boolean;
}

export const EditServingDialog = ({ serving, onCancel, onSave }: Props) => {
  const { t } = useTranslation();

  if (!serving) return null;

  return (
    <Modal open={serving !== null} onClose={onCancel} title={serving._id ? t('Serving.EditServing') : t('Serving.NewServing')}>
      {serving && <EditServingForm serving={serving as Serving} onSave={onSave} onCancel={onCancel} />}
    </Modal>
  );
};

export const EditServingForm = React.forwardRef(({ serving, onSave, onCancel, showActions = true }: FromProps, ref) => {
  const { t } = useTranslation();
  const {
    vessel,
    setVessel,
    size,
    price,
    currency,
    volumeUnit,
    validating,
    deleteDialogOpen,
    handleSave,
    selectVessel,
    selectVolumeUnit,
    options,
    onCancelDelete,
    onDeleteVessel,
    handlePriceChange,
    setDeleteDialogOpen,
    setVesselToDelete,
    setSize,
    setCurrency
  } = useEditServings(serving, onSave);

  useImperativeHandle(ref, () => ({
    handleSave
  }));
  const { paper } = useStyles();

  return (
    <div className="grid grid-cols-2 gap-4 p-6 pt-8 pb-20">
      <div className="col-span-2">
        <Autocomplete
          freeSolo
          selectOnFocus
          value={getVesselLabel(vessel, volumeUnit)}
          // @ts-ignore
          options={options}
          groupBy={(option) => {
            return option.type;
          }}
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            // @ts-ignore

            if (option.inputValue) {
              // @ts-ignore
              return option.inputValue;
            }
            return option.name;
          }}
          // @ts-ignore
          filterOptions={(options, params) => {
            // @ts-ignore

            const filtered = filter(options, params);
            // Suggest the creation of a new value
            if (params.inputValue !== '') {
              filtered.push({
                inputValue: params.inputValue,
                name: t('Serving.AddVessel', { vessel: params.inputValue }),
                type: 'Custom',
                isNew: true
              });
            }

            return filtered;
          }}
          onChange={(_, newValue) => {
            if (newValue === null || newValue === undefined) {
              setVessel({ _id: '', name: '', size: 0, createdBy: '', isNew: false });
              return;
            }
            if (typeof newValue === 'string') {
              selectVessel({
                _id: '',
                name: newValue,
                size: 0,
                createdBy: '',
                isNew: true
              });
              // @ts-ignore
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              selectVessel({
                _id: '',
                // @ts-ignore
                name: newValue.inputValue,
                size: 0,
                createdBy: '',
                isNew: true
              });
            } else {
              selectVessel(newValue);
            }
          }}
          PaperComponent={(props) => <Paper {...props} className={paper} />}
          renderOption={(option: DisplayVessel) => (
            <li style={{ position: 'relative', width: '100%' }}>
              {option.type === 'Custom' && !option.isNew && (
                <IconButton
                  style={{ zIndex: 'calc(infinity)', position: 'absolute', right: 0, top: -12 }}
                  edge="end"
                  onClick={(event) => {
                    event.stopPropagation();
                    setVesselToDelete(option);
                    setDeleteDialogOpen(true);
                  }}>
                  <Delete />
                </IconButton>
              )}
              {getVesselLabel(option, volumeUnit)}
            </li>
          )}
          renderInput={(params) => (
            <TextField error={validating && !vessel.name} {...params} label={t('Serving.VesselType')} variant="outlined" />
          )}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          value={volumeUnit}
          options={VOLUME_UNITS_ARRAY.map((value) => ({ value: value.value, label: value.name }))}
          onOptionSelect={(value) => selectVolumeUnit(value as string)}
          label={t('Serving.VolumeUnit')}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          value={currency}
          options={CURRENCIES_ARRAY.map((value) => ({ value: value.id, label: `${value.name} (${value.symbol})` }))}
          onOptionSelect={(value) => setCurrency(value as string)}
          label={t('Serving.Currency')}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          error={validating && !size}
          type="number"
          label={t('Serving.Size')}
          value={size}
          onChange={(value) => setSize(Number(value) || '')}
          endIcon={`${volumeUnit}.`}
          numberMode="float"
          step={0.1}
        />
      </div>
      <div className="col-span-2 md:col-span-1">
        <Input
          error={validating && !price}
          value={price}
          type="number"
          label={t('Serving.Price')}
          onChange={handlePriceChange}
          startIcon={getCurrencySign(currency)}
          numberMode="float"
          step={0.1}
        />
      </div>
      {showActions && (
        <div className="absolute bottom-0 left-0 w-full bg-white p-4 flex justify-end gap-x-2 z-10">
          {onCancel && (
            <Button variant={'secondary'} onClick={onCancel}>
              {t('Serving.Cancel')}
            </Button>
          )}
          <Button onClick={handleSave}>{serving._id ? t('Serving.Update') : t('Serving.Add')}</Button>
        </div>
      )}
      <DeleteDialog
        show={deleteDialogOpen}
        onCancel={onCancelDelete}
        title={t('Serving.DeleteCustomVesselTitle')}
        description={t('Serving.DeleteCustomVesselDesc')}
        onDelete={onDeleteVessel}
      />
    </div>
  );
});
