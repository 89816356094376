import React, { useState, useRef } from 'react';
import { Button } from './Button';
import ChevronUp from '../../images/chevron-up';
import ChevronDown from '../../images/chevron-down';

interface Option {
  label: string;
  value: string | number;
}

interface DropdownProps {
  label: string;
  options: Option[];
  className?: string;
  align?: 'left' | 'right';
  buttonProps?: React.ComponentProps<typeof Button>;
  onChange?: (value: string | number) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({ label, options, className = '', align = 'left', buttonProps, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleFocusChange = (event: React.FocusEvent) => {
    if (!containerRef.current?.contains(event.relatedTarget as Node)) {
      setIsOpen(false);
    }
  };

  return (
    <div className="relative inline-block" ref={containerRef} onBlur={handleFocusChange} tabIndex={-1}>
      <Button onClick={() => setIsOpen(!isOpen)} {...buttonProps}>
        <span className="flex items-center gap-2">
          {label}
          {isOpen ? <ChevronUp /> : <ChevronDown />}
        </span>
      </Button>

      {isOpen && (
        <div
          className={`
            absolute z-50 mt-2 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5
            ${align === 'right' ? 'right-0' : 'left-0'}
            ${className}
          `}>
          <div role="menu" aria-orientation="vertical">
            {options.map((option) => (
              <button
                key={option.value}
                className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-left"
                role="menuitem"
                onClick={() => {
                  onChange?.(option.value);
                  setIsOpen(false);
                }}>
                {option.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
