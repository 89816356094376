import { useMutation } from 'react-apollo';
import { find, without } from 'lodash';
import shortid from 'shortid';
import { UPDATE_SCREEN } from '../../mutations';
import { cleanCachedObjectForMutation } from '../../../../util/graphql';

export const usePageActions = (screen: any) => {
  const [saveScreen] = useMutation(UPDATE_SCREEN);

  const addPage = () => {
    let page = {
      name: 'New Page',
      rotationDuration: 10,
      key: shortid.generate(),
      number: 1
    };

    const numbers = (screen?.pages || []).map((s: any) => s.number);
    if (numbers.length > 0) {
      let [min, max] = [Math.min(...numbers), Math.max(...numbers)];
      let out = Array.from(Array(max - min), (v, i) => i + min).filter((i) => !numbers.includes(i));
      if (out.length > 0) page.number = out[0];
      else page.number = max + 1;
    }

    return page;
  };

  const handleSavePage = async (page: any) => {
    if (!screen) return [];

    let allPages = [...(screen.pages || [])];
    let matchedPage = find(allPages, (currPage) => currPage.key === page.key);
    if (!matchedPage) allPages.push(page);
    else Object.assign(matchedPage, page);

    const cleanScreen = cleanCachedObjectForMutation({
      ...screen,
      pages: allPages
    });

    if (cleanScreen.device && typeof cleanScreen.device === 'object') {
      cleanScreen.device = cleanScreen.device._id;
    }

    await saveScreen({ variables: { screen: cleanScreen } });
    return allPages;
  };

  const handleDeletePage = async (pageKey: string) => {
    if (!screen) return [];

    let allPages = [...(screen.pages || [])];
    let matchedPage = find(allPages, (currPage) => currPage.key === pageKey);
    if (matchedPage) {
      allPages = without(allPages, matchedPage);
      const cleanScreen = cleanCachedObjectForMutation({
        ...screen,
        pages: allPages
      });
      if (cleanScreen.device && typeof cleanScreen.device === 'object') {
        cleanScreen.device = cleanScreen.device._id;
      }
      await saveScreen({ variables: { screen: cleanScreen } });
    }
    return allPages;
  };

  return { addPage, handleSavePage, handleDeletePage };
};
