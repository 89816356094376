import React from 'react';

interface Props {
  theme: { name: string; themeOptions: any };
}

export const ThemeTile = ({ theme: { name, themeOptions } }: Props) => {
  const tileStyle = {
    backgroundColor: themeOptions.backgroundColor,
    ...(themeOptions.backgroundImageUrl && {
      backgroundImage: `url(${themeOptions.backgroundImageUrl})`,
      backgroundSize: 'cover'
    })
  };

  const backdropClasses = `
    flex flex-1 flex-col justify-center p-5
    ${themeOptions.showBackgroundImageHelper ? 'bg-black/40' : ''}
  `.trim();

  const itemDescriptionClasses = `
    flex flex-col
    ${themeOptions.menuType === 'dense' ? 'flex-row-reverse justify-end' : ''}
  `.trim();

  return (
    <div
      className={`
        h-full w-full flex cursor-pointer
        ${themeOptions.menuType === 'dense' ? 'text-base' : 'text-xl'}
        ${themeOptions.textSize === 'condensed' ? 'text-sm' : ''}
      `.trim()}
      style={tileStyle}>
      <div className={backdropClasses}>
        <div className={itemDescriptionClasses}>
          <div className="font-bold font-open-sans" style={{ color: themeOptions.foregroundColor2 }}>
            {name}
          </div>
          {!themeOptions.hideBeverageSourceName && (
            <div
              className="uppercase opacity-80 font-oswald"
              style={{
                color: themeOptions.foregroundColor
              }}>
              {name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
