import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomLink from '../../../../components/CustomLink';
import FileUpload from '../../../../components/FileUpload';
import { Checkbox } from '../../../../components/shared/Checkbox';
import { ColorPicker } from '../../../../components/shared/ColorPicker';
import { FontPicker } from '../../../../components/shared/FontPicker';
import { Input } from '../../../../components/shared/Input';
import QuestionIcon from '../../../../images/icon-question';
import { TAP_ORIENTATION } from '../../../../util/enums';
import { MAX_SCREEN_IMAGE_SIZE_BYTES } from '../../../../util/files';
import { HelperPopups } from './HelperPopups';
import { useHelperPopups } from './HelperPopups/useHelperPopups';

interface Props {
  menuType: string;
  setMenuType: (value: string) => void;
  columns: number;
  setColumns: (value: number) => void;
  primaryFont: string;
  setPrimaryFont: (value: string) => void;
  secondaryFont: string;
  setSecondaryFont: (value: string) => void;
  backgroundColor: string;
  setBackgroundColor: (value: string) => void;
  foregroundColor: string;
  setForegroundColor: (value: string) => void;
  foregroundColor2: string;
  setForegroundColor2: (value: string) => void;
  textSize: string;
  setTextSize: (value: string) => void;
  tapsOrientation: string;
  setTapsOrientation: (value: string) => void;
  setReverseBreweryAndName: (value: boolean) => void;
  kegMonitoringPartner: string;
  setKegMonitoringPartner: (value: string) => void;
  kegMonitoringApiKey: string;
  setKegMonitoringApiKey: (value: string) => void;
  showHeading: boolean;
  setShowHeading: (value: boolean) => void;
  showTapNumber: boolean;
  setShowTapNumber: (value: boolean) => void;
  hideBeverageSourceName: boolean;
  setHideBeverageSourceName: (value: boolean) => void;
  reverseBreweryAndName: boolean;
  showServings: boolean;
  setShowServings: (value: boolean) => void;
  showServingSize: boolean;
  setShowServingSize: (value: boolean) => void;
  showServingCurrency: boolean;
  setShowServingCurrency: (value: boolean) => void;
  showStripes: boolean;
  setShowStripes: (value: boolean) => void;
  showLocation: boolean;
  setShowLocation: (value: boolean) => void;
  showTapsDescription: boolean;
  setShowTapsDescription: (value: boolean) => void;
  premium: boolean;
  showLogo: boolean;
  setShowLogo: (value: boolean) => void;
  beverageImageSize: string;
  setBeverageImageSize: (value: string) => void;
  setShowBeverageImage: (value: boolean) => void;
  backgroundImageUrl: string | null;
  backgroundImagePreviewUrl: string | null;
  showBackgroundImageHelper: boolean;
  setShowBackgroundImageHelper: (value: boolean) => void;
  handleBackgroundImageSelection: (file: File) => void;
  removeBackgroundImage: () => void;
}

export const PageStyleCustom = ({
  menuType,
  setMenuType,
  columns,
  setColumns,
  primaryFont,
  setPrimaryFont,
  secondaryFont,
  setSecondaryFont,
  backgroundColor,
  setBackgroundColor,
  foregroundColor,
  setForegroundColor,
  foregroundColor2,
  setForegroundColor2,
  textSize,
  setTextSize,
  tapsOrientation,
  setTapsOrientation,
  setReverseBreweryAndName,
  kegMonitoringPartner,
  setKegMonitoringPartner,
  kegMonitoringApiKey,
  setKegMonitoringApiKey,
  showHeading,
  setShowHeading,
  showTapNumber,
  setShowTapNumber,
  hideBeverageSourceName,
  setHideBeverageSourceName,
  reverseBreweryAndName,
  showServings,
  setShowServings,
  showServingSize,
  setShowServingSize,
  showServingCurrency,
  setShowServingCurrency,
  showStripes,
  setShowStripes,
  showLocation,
  setShowLocation,
  showTapsDescription,
  setShowTapsDescription,
  premium,
  showLogo,
  setShowLogo,
  beverageImageSize,
  setBeverageImageSize,
  setShowBeverageImage,
  backgroundImageUrl,
  backgroundImagePreviewUrl,
  showBackgroundImageHelper,
  setShowBackgroundImageHelper,
  handleBackgroundImageSelection,
  removeBackgroundImage
}: Props) => {
  const { t } = useTranslation();

  const helperPopups = useHelperPopups();

  return (
    <>
      <HelperPopups {...helperPopups} />

      <div className="grid grid-cols-12 gap-4">
        <h3 className="text-dark-grey uppercase font-bold col-span-12 flex items-center gap-1">
          <span>{t('PageEditForm.Size')}</span>
          <button onClick={() => helperPopups.openSizeHelper()}>
            <QuestionIcon className="text-light-grey" />
          </button>
        </h3>
        <Input
          label={t('PageEditForm.Layout')}
          value={menuType}
          options={[
            { value: 'dense', label: t('PageEditForm.Dense') },
            { value: 'middle', label: t('PageEditForm.MiddleGround') },
            { value: 'normal', label: t('PageEditForm.Relaxed') }
          ]}
          onOptionSelect={(value) => {
            if (value !== 'dense') {
              setReverseBreweryAndName(false);
            }
            setMenuType(value as string);
          }}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Input
          label={t('PageEditForm.TextSize')}
          value={textSize}
          options={[
            { value: 'condensed', label: t('PageEditForm.Small') },
            { value: 'medium', label: t('PageEditForm.Medium') },
            { value: 'normal', label: t('PageEditForm.Large') },
            { value: 'xl', label: t('PageEditForm.ExtraLarge') },
            { value: 'xl2', label: t('PageEditForm.ExtraExtraLarge') },
            { value: 'xl3', label: t('PageEditForm.SuperSize') },
            { value: 'xxl', label: t('PageEditForm.HulkMode') }
          ]}
          onOptionSelect={(value) => setTextSize(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Input
          label={t('PageEditForm.Columns')}
          value={columns}
          options={[
            { value: 1, label: '1' },
            { value: 2, label: '2' },
            { value: 3, label: '3' }
          ]}
          onOptionSelect={(value) => setColumns(Number(value))}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Input
          label={t('PageEditForm.TapsOrientation')}
          value={tapsOrientation}
          options={[
            { value: TAP_ORIENTATION.LEFT_TO_RIGHT, label: t('PageEditForm.LeftToRight') },
            { value: TAP_ORIENTATION.TOP_TO_BOTTOM, label: t('PageEditForm.TopToBottom') }
          ]}
          onOptionSelect={(value) => setTapsOrientation(value as TAP_ORIENTATION)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
      </div>

      <div className="grid grid-cols-12 gap-2 mt-10">
        <h3 className="col-span-12 font-bold text-dark-grey uppercase flex items-center gap-1">
          <span>{t('PageEditForm.Colors')}</span>
          <button onClick={() => helperPopups.openColorsHelper()}>
            <QuestionIcon className="text-light-grey" />
          </button>
        </h3>
        <ColorPicker
          label={t('PageEditForm.BackgroundColor')}
          value={backgroundColor}
          onChange={(color) => setBackgroundColor(color)}
          containerClassName="col-span-12 md:col-span-4"
        />
        <ColorPicker
          label={t('PageEditForm.TextColor')}
          value={foregroundColor}
          onChange={(color) => setForegroundColor(color)}
          containerClassName="col-span-12 md:col-span-4"
        />
        <ColorPicker
          label={t('PageEditForm.AltTextColor')}
          value={foregroundColor2}
          onChange={(color) => setForegroundColor2(color)}
          containerClassName="col-span-12 md:col-span-4"
        />
      </div>

      <div className="grid grid-cols-12 gap-4 mt-10">
        <h3 className="col-span-12 md:col-span-6 font-bold text-dark-grey uppercase">{t('PageEditForm.Fonts')}</h3>

        <h3 className="hidden md:block col-span-6 font-bold text-dark-grey uppercase">{t('PageEditForm.Partners')}</h3>

        <FontPicker
          value={primaryFont}
          label={t('PageEditForm.Primary')}
          onChange={(font) => setPrimaryFont(font)}
          containerClassName="col-span-12 md:col-span-3"
        />
        <FontPicker
          value={secondaryFont}
          label={t('PageEditForm.Secondary')}
          onChange={(font) => setSecondaryFont(font)}
          containerClassName="col-span-12 md:col-span-3"
        />

        <h3 className="md:hidden col-span-12 font-bold text-dark-grey uppercase mt-6">{t('PageEditForm.Partners')}</h3>
        <Input
          label={t('PageEditForm.KegMonitoring')}
          value={kegMonitoringPartner}
          options={[
            { value: 'none', label: t('PageEditForm.None') },
            { value: 'kegtron', label: t('PageEditForm.Kegtron') }
          ]}
          onOptionSelect={(value) => setKegMonitoringPartner(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
        />
        <Input
          label={t('PageEditForm.KegtronApiKey')}
          value={kegMonitoringApiKey}
          onChange={(value) => setKegMonitoringApiKey(value as string)}
          className="w-full"
          containerClassName="col-span-12 md:col-span-3"
          disabled={kegMonitoringPartner !== 'kegtron'}
        />
      </div>
      <div className="flex flex-col mt-10">
        <div className="flex flex-nowrap gap-1 text-dark-grey items-baseline mb-4">
          <h3 className="font-bold uppercase">{t('PageEditForm.Options')}</h3>
          <span className="text-xs">{t('PageEditForm.OptionsSubtitle')}</span>
        </div>
        <div className="flex flex-col md:grid md:grid-cols-2 gap-4 md:gap-0 items-start">
          <div className="grid grid-cols-2 gap-5 items-start w-full ">
            <Checkbox label={t('PageEditForm.ShowPageName')} checked={showHeading} onChange={(checked) => setShowHeading(checked)} />
            <Checkbox label={t('PageEditForm.ShowServings')} checked={showServings} onChange={(checked) => setShowServings(checked)} />
            <Checkbox label={t('PageEditForm.ShowTapNumber')} checked={showTapNumber} onChange={(checked) => setShowTapNumber(checked)} />
            <Checkbox
              label={t('PageEditForm.ShowServingSize')}
              checked={showServingSize}
              onChange={(checked) => setShowServingSize(checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowBreweryName')}
              checked={!hideBeverageSourceName}
              onChange={(checked) => setHideBeverageSourceName(!checked)}
            />
            <Checkbox
              label={t('PageEditForm.ShowServingCurrency')}
              checked={showServingCurrency}
              onChange={(checked) => setShowServingCurrency(checked)}
            />
            {menuType === 'dense' && (
              <Checkbox
                label={t('PageEditForm.ReverseBreweryAndName')}
                checked={reverseBreweryAndName}
                onChange={(checked) => setReverseBreweryAndName(checked)}
                className="col-span-2"
              />
            )}
          </div>
          <div className="grid grid-cols-2 gap-5 items-start justify-start w-full">
            <div className="flex gap-1">
              <Checkbox label={t('PageEditForm.ShowStripes')} checked={showStripes} onChange={(checked) => setShowStripes(checked)} />
              <button onClick={() => helperPopups.openOptionsHelper()}>
                <QuestionIcon className="text-light-grey" />
              </button>
            </div>
            <Checkbox label={t('PageEditForm.ShowLocation')} checked={showLocation} onChange={(checked) => setShowLocation(checked)} />
            <Checkbox
              label={t('PageEditForm.ShowTapsDescription')}
              checked={showTapsDescription}
              onChange={(checked) => setShowTapsDescription(checked)}
            />
            {premium && <Checkbox label={t('PageEditForm.ShowLogo')} checked={showLogo} onChange={(checked) => setShowLogo(checked)} />}
            <Input
              label={t('PageEditForm.BeverageImages')}
              value={beverageImageSize}
              options={[
                { value: 'none', label: t('PageEditForm.None') },
                { value: 'small', label: t('PageEditForm.SmallRound') },
                { value: 'small_square', label: t('PageEditForm.SmallSquare') },
                { value: 'large', label: t('PageEditForm.LargeRound') },
                { value: 'large_square', label: t('PageEditForm.LargeSquare') }
              ]}
              onOptionSelect={(value) => {
                setBeverageImageSize(value as string);
                setShowBeverageImage(value !== 'none');
              }}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-4">
        <h3 className="col-span-12 mt-8 font-bold text-dark-grey uppercase">{t('PageEditForm.BackgroundImage')}</h3>
        <div className="col-span-12">
          <FileUpload
            id={'background_image_selection'}
            onFileSelected={handleBackgroundImageSelection}
            maxFileSize={MAX_SCREEN_IMAGE_SIZE_BYTES}
          />
        </div>

        {(backgroundImageUrl || backgroundImagePreviewUrl) && (
          <Checkbox
            label={t('PageEditForm.AutoDarken')}
            checked={showBackgroundImageHelper}
            onChange={(checked) => setShowBackgroundImageHelper(checked)}
            className="col-span-12 md:col-span-6"
          />
        )}

        <div className="col-span-12">
          {backgroundImagePreviewUrl && (
            <img
              className={`w-full mt-0 sm:h-[250px] sm:w-auto ${!!showBackgroundImageHelper && 'brightness(70%)'}`}
              src={backgroundImagePreviewUrl}
              alt="Preview"
            />
          )}
          {!backgroundImagePreviewUrl && backgroundImageUrl && (
            <img
              className={`w-full mt-0 sm:h-[250px] sm:w-auto ${!!showBackgroundImageHelper && 'brightness(70%)'}`}
              src={backgroundImageUrl}
              alt="Custom"
            />
          )}
          {(backgroundImageUrl || backgroundImagePreviewUrl) && (
            <p>
              <CustomLink onClick={removeBackgroundImage}>{t('PageEditForm.RemoveBackgroundImage')}</CustomLink>
            </p>
          )}
        </div>
      </div>
    </>
  );
};
