import { Modal } from '../../../../../components/shared/Modal';
import { CloseIcon } from '../../../../../images/CloseIcon';
import { ColorsHelper } from './ColorsHelper';
import { OptionsHelper } from './OptionsHelper';
import { SizeHelper } from './SizeHelper';
import { HelperPopupType } from './types';
import { useHelperPopups } from './useHelperPopups';

const HELPER_MAP: Record<HelperPopupType, React.ReactNode> = {
  [HelperPopupType.Size]: <SizeHelper />,
  [HelperPopupType.Colors]: <ColorsHelper />,
  [HelperPopupType.Options]: <OptionsHelper />
};

export const HelperPopups = (props: ReturnType<typeof useHelperPopups>) => {
  console.log(props);
  return (
    <Modal open={!!props.activePopup} onClose={props.closePopup} className="p-14 xs:max-w-[390px] xs:p-10">
      <button onClick={props.closePopup} className="w-8 h-8 absolute right-6 top-6 flex items-center justify-center">
        <CloseIcon className="w-6 h-6 text-dark-grey" />
      </button>{' '}
      {props.activePopup && HELPER_MAP[props.activePopup]}
    </Modal>
  );
};
