import React, { useState, useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../contexts/session';

import { PageCard } from './PageCard';

import { UPDATE_SCREEN, CREATE_SCREEN, DELETE_SCREEN } from './mutations';
import { cleanCachedObjectForMutation, spreadMutations, getMessageFromError } from '../../util/graphql';
import { ConnectDeviceDialog } from './connect/ConnectDeviceDialog';
import { FeedbackBar } from '../../components/FeedbackBar';
import { Alert } from '../../components/shared/Alert';
import { Button } from '../../components/shared/Button';
import PlusIcon from '../../images/icon-plus';
import ArrowBack from '../../images/icon-arrow';
import CastIcon from '../../images/cast';
import { Input } from '../../components/shared/Input';

export const ScreenEditForm = withRouter(({ history, screen, onClose }) => {
  const { t } = useTranslation();
  const { user, currentLocation } = useContext(SessionContext);

  const [errorMessage, setErrorMessage] = useState();

  const [name, setName] = useState(screen.name);
  const [orientation, setOrientation] = useState(screen.orientation || 'landscape_0');

  const [cacheLocation, setCacheLocation] = useState(currentLocation);

  const [connect, setConnect] = useState(false);

  const [[createScreen, saveScreen, deleteScreen], { loading }] = spreadMutations([
    useMutation(CREATE_SCREEN),
    useMutation(UPDATE_SCREEN),
    useMutation(DELETE_SCREEN)
  ]);

  useEffect(() => {
    if (cacheLocation !== currentLocation) {
      setCacheLocation(currentLocation);
      history.push(`/screens`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocation, cacheLocation]);

  const handleSave = async (close) => {
    // remove all the __typename properties since our mutation won't like them
    let cleanScreen = cleanCachedObjectForMutation({
      ...screen,
      name,
      orientation
    });

    cleanScreen = Object.assign({ createdBy: user._id, location: currentLocation?._id }, cleanScreen);

    // this seems messy
    if (cleanScreen.device && typeof cleanScreen.device === 'object') {
      cleanScreen.device = cleanScreen.device._id;
    }

    try {
      let data;
      if (cleanScreen._id) {
        await saveScreen({ variables: { screen: cleanScreen } });
      } else {
        data = await createScreen({ variables: { screen: cleanScreen } });
      }

      close && history.push(`/screens`);

      return data;
    } catch (e) {
      setErrorMessage(getMessageFromError(e));
      console.log(e);
    }
  };

  const handleDelete = async () => {
    await deleteScreen({ variables: { id: screen._id } });
    onClose();
  };

  const handlePreview = () => {
    const newWindow = window.open('', '_blank');
    handleSave()
      .then(() => {
        newWindow.location.href = `/screens/${screen?._id}/preview`;
      })
      .catch((error) => {
        newWindow.close();
        console.error('Error while saving:', error);
      });
  };

  const handleConnect = async () => {
    await handleSave(false);
    setConnect(true);
  };

  const handleNewPage = async () => {
    if (!screen._id) {
      try {
        const { data } = await handleSave(false);
        history.push(`/screens/${data.screenCreateOne.record._id}/pages/new`);
      } catch (e) {
        setErrorMessage(getMessageFromError(e));
        console.error('Error while saving screen:', e);
      }
    } else {
      history.push(`/screens/${screen._id}/pages/new`);
    }
  };

  return (
    <div className="flex flex-col font-public-sans">
      <div className="grid grid-cols-12">
        <div className="col-span-2 px-4 py-5">
          <ArrowBack onClick={() => history.push(`/screens`)} className="cursor-pointer" />
        </div>

        <div className="col-span-12">
          <Alert severity="info" variant="standard">
            <p className="flex items-center justify-center">
              <span className="inline-flex items-center flex-wrap text-center justify-center">
                {`${t('ScreenEdit.AddingPage')} `}
                <CastIcon className="mx-1" alt={t('ScreenEdit.Cast')} />
                {` ${t('ScreenEdit.ButtonBelow')}`}
              </span>
            </p>
          </Alert>
        </div>

        <div className="col-span-12 p-8">
          <FeedbackBar message={errorMessage} />

          <div className="space-y-4">
            <h2 className="font-bold mt-0 text-dark-grey uppercase">{t('ScreenEdit.Basics')}</h2>

            <div className="grid md:grid-cols-2 gap-4">
              <Input label={t('ScreenEdit.Name')} value={name} onChange={setName} className="w-full" />
              <Input
                label={t('ScreenEdit.Orientation')}
                value={orientation}
                options={[
                  { value: 'landscape_0', label: 'Landscape (default)' },
                  { value: 'landscape_180', label: t('ScreenEdit.LandscapeReversed') },
                  { value: 'portrait_90', label: 'Portrait' },
                  { value: 'portrait_270', label: t('ScreenEdit.PortraitReversed') }
                ]}
                onOptionSelect={setOrientation}
                className="w-full"
              />
            </div>
          </div>

          <div className="pt-10">
            <div className="flex justify-between items-center mb-4">
              <h2 className="font-bold text-dark-grey uppercase">{t('ScreenEdit.PagesTitle')}</h2>
              <Button variant="secondary" className="rounded-full px-5" onClick={handleNewPage}>
                <PlusIcon />
                {t('PageEditForm.NewPage')}
              </Button>
            </div>

            <div className="block md:hidden h-full">
              <div className="flex flex-col">
                {sortBy(screen.pages, (page) => page.number).map((page, i) => (
                  <PageCard key={i} page={page} screenId={screen._id} />
                ))}
              </div>
            </div>

            <div className="hidden md:block">
              <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                {sortBy(screen.pages, (page) => page.number).map((page, i) => (
                  <PageCard key={i} page={page} screenId={screen._id} />
                ))}
              </div>
            </div>

            <div className="flex flex-col items-center text-center px-8 text-md mt-4 mb-16">
              {screen.pages.length === 0 && (
                <>
                  <p>{t('ScreenEdit.Pages')}</p>
                  <p className="mt-5">{t('ScreenEdit.AddPages')}</p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {connect && <ConnectDeviceDialog screen={screen} onCancel={() => setConnect(false)} />}

      <div className="flex border justify-between flex-row p-4">
        <div className="flex">
          <Button variant="secondary" disabled={!screen._id || !screen.pages?.length} onClick={handleConnect}>
            <CastIcon />
          </Button>
          <div className="hidden sm:block ml-2">
            <Button variant="secondary" disabled={!screen._id || screen.pages?.length === 0} onClick={handlePreview}>
              <span className="md:hidden">{t('ScreenEdit.Preview')}</span>
              <span className="hidden md:inline">{t('Preview.PreviewScreen')}</span>
            </Button>
          </div>
        </div>

        <div className="flex gap-2">
          <Button variant="destructive" disabled={!screen._id} onClick={handleDelete}>
            <span className="md:hidden">{t('ScreenEdit.Delete')}</span>
            <span className="hidden md:inline">{t('Screens.DeleteScreen')}</span>
          </Button>
          <Button variant="primary" disabled={loading} onClick={() => handleSave(true)}>
            <span className="md:hidden">{t('ScreenEdit.Save')}</span>
            <span className="hidden md:inline">{t('TapForm.Save')}</span>
          </Button>
        </div>
      </div>
    </div>
  );
});
