import React, { useState } from 'react';
import { TabProps } from './Tab';

interface TabsProps {
  children: React.ReactElement<TabProps>[];
  defaultTab?: number;
  className?: string;
  leftIcon?: React.ReactNode;
  onLeftIconClick?: () => void;
  selectedTab?: number;
  onSelectedTabChange?: (index: number) => void;
}

export const Tabs: React.FC<TabsProps> = ({
  children,
  defaultTab = 0,
  className = '',
  leftIcon,
  onLeftIconClick,
  selectedTab: externalSelectedTab,
  onSelectedTabChange
}) => {
  const [internalSelectedTab, setInternalSelectedTab] = useState(defaultTab);

  const selectedTab = externalSelectedTab !== undefined ? externalSelectedTab : internalSelectedTab;

  const handleTabChange = (index: number) => {
    if (onSelectedTabChange) {
      onSelectedTabChange(index);
    } else {
      setInternalSelectedTab(index);
    }
  };

  return (
    <div className={`${className}`}>
      <div className="border-b-1 border-b-accenter-grey">
        <nav className="flex gap-6 md:gap-10" role="tablist">
          {leftIcon && (
            <div onClick={onLeftIconClick} className="cursor-pointer ml-4">
              {leftIcon}
            </div>
          )}
          {children.map((child, index) => (
            <button
              key={index}
              role="tab"
              aria-selected={selectedTab === index}
              onClick={() => handleTabChange(index)}
              className={`
                pb-2 text-base font-semibold
                border-b-2 
                focus:outline-none
                transition-colors duration-200
                ${selectedTab === index ? 'border-orange text-orange' : 'border-transparent text-light-blackish hover:text-gray-700'}
              `}>
              {child.props.label}
            </button>
          ))}
        </nav>
      </div>

      <div>{children.map((child, index) => React.cloneElement(child, { key: index, isActive: index === selectedTab }))}</div>
    </div>
  );
};
