import React from 'react';
import { VOLUME_UNITS_ARRAY } from '../util/volumeUnits';

import { Input } from './shared/Input';

interface VolumeUnitDropdownProps {
  volumeUnit: string;
  setVolumeUnit: (volumeUnit: string) => void;
  label?: string;
}

export function VolumeUnitDropdown({ volumeUnit, setVolumeUnit, label = 'Volume Unit' }: VolumeUnitDropdownProps) {
  return (
    <Input
      value={volumeUnit}
      options={VOLUME_UNITS_ARRAY.map((value) => ({ value: value.value, label: `${value.name} (${value.value})` }))}
      onOptionSelect={(value) => setVolumeUnit(value as string)}
      label={label}
    />
  );
}
