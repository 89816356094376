import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/shared/Button';
import { Card } from '../../../../components/shared/Card';

import TapListIcon from '../../../../images/taps.png';
import ImageIcon from '../../../../images/upload-image.png';

interface PageTypeSelectionProps {
  interstitialType: string;
  setInterstitialType: (type: string) => void;
  onConfirm: () => void;
}

export const PageTypeSelection = ({ interstitialType, setInterstitialType, onConfirm }: PageTypeSelectionProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-center">
        <h2 className="text-center text-md">{t('PageEditForm.PageAdd')}</h2>
      </div>
      <div className="flex justify-center gap-4">
        <Card
          variant="outlined"
          selected={interstitialType === 'taps'}
          className="flex flex-col items-center justify-center text-center cursor-pointer w-[155px] h-[253px] md:w-[225px] md:h-[253px]"
          onClick={() => setInterstitialType('taps')}>
          <Card.Content className="flex flex-col items-center gap-6">
            <img src={TapListIcon} alt="Tap List" className="h-[122px] w-auto mx-auto" />
            <p className="mb-0 uppercase font-bold text-md">{t('PageEditForm.TapList')}</p>
          </Card.Content>
        </Card>
        <Card
          variant="outlined"
          selected={interstitialType === 'image'}
          className="flex flex-col items-center justify-center text-center cursor-pointer w-[155px] h-[253px] md:w-[225px] md:h-[253px]"
          onClick={() => setInterstitialType('image')}>
          <Card.Content className="flex flex-col items-center gap-6">
            <img src={ImageIcon} alt="Upload Your Own" className="h-[122px] w-auto mx-auto" />
            <p className="mb-0 uppercase font-bold text-md">{t('PageEditForm.UploadImage')}</p>
          </Card.Content>
        </Card>
      </div>
      <div className="border-t gap-2 p-4 flex justify-end items-center">
        <Button onClick={onConfirm} disabled={!interstitialType}>
          {t('PageEditForm.Next')}
        </Button>
      </div>
    </div>
  );
};
