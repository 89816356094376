import { Serving, Vessel } from '../types';
import { DEFAULT_CURRENCY } from './currencies';
import { DEFAULT_VOLUME_UNIT, volumeUnitConverter } from './volumeUnits';

const VESSEL_TYPES = {
  BEER: 'Beer',
  WINE: 'Wine',
  SPIRITS: 'Spirits'
};

/*
 *
 * Note:
 *  We are considering that the default value for vessels is measured in ounces (oz).
 *
 */
export const VESSELS: Vessel[] = [
  { type: VESSEL_TYPES.BEER, name: 'Pint', size: 16 },
  { type: VESSEL_TYPES.BEER, name: 'Taster', size: 3 },
  { type: VESSEL_TYPES.BEER, name: '1/2 Pint', size: 8 },
  { type: VESSEL_TYPES.BEER, name: '1/3 Pint', size: 5.333 },
  { type: VESSEL_TYPES.BEER, name: '2/3 Pint', size: 10.666 },
  { type: VESSEL_TYPES.BEER, name: 'Imperial', size: 20 },
  { type: VESSEL_TYPES.BEER, name: 'Growler', size: 64 },
  { type: VESSEL_TYPES.BEER, name: 'Half Growler', size: 32 },
  { type: VESSEL_TYPES.BEER, name: 'Crowler', size: 32 },
  { type: VESSEL_TYPES.BEER, name: 'Pitcher', size: 64 },
  { type: VESSEL_TYPES.BEER, name: 'Tulip', size: 10 },
  { type: VESSEL_TYPES.BEER, name: 'Snifter', size: 10 },
  { type: VESSEL_TYPES.BEER, name: 'Schooner', size: 10 },
  { type: VESSEL_TYPES.BEER, name: 'Bottle', size: 0 },
  { type: VESSEL_TYPES.BEER, name: 'Mug', size: 24 },
  { type: VESSEL_TYPES.BEER, name: 'Can', size: 0 },
  { type: VESSEL_TYPES.BEER, name: 'Pour', size: 0 },
  { type: VESSEL_TYPES.BEER, name: '1/2 Keg', size: 1984 },
  { type: VESSEL_TYPES.BEER, name: '1/4 Keg', size: 992 },
  { type: VESSEL_TYPES.BEER, name: '1/6 Keg', size: 660 },
  { type: VESSEL_TYPES.BEER, name: 'Keg', size: 3968 },
  { type: VESSEL_TYPES.WINE, name: 'Glass', size: 5 },
  { type: VESSEL_TYPES.WINE, name: 'Taste', size: 3 },
  { type: VESSEL_TYPES.SPIRITS, name: 'Single', size: 1.5 },
  { type: VESSEL_TYPES.SPIRITS, name: 'Double', size: 3 }
];

export const BeerVessels = VESSELS.filter(({ type }) => type === VESSEL_TYPES.BEER);
export const WineVessels = VESSELS.filter(({ type }) => type === VESSEL_TYPES.WINE);
export const SpiritsVessels = VESSELS.filter(({ type }) => type === VESSEL_TYPES.SPIRITS);

export const VESSEL_PER_NAME = VESSELS.reduce((mappedVessels, vessel) => {
  mappedVessels[vessel.name] = vessel;
  return mappedVessels;
}, {} as Record<string, Vessel>);

export const getServingSizeLabel = (serving: Serving) => {
  const { volumeUnit = DEFAULT_VOLUME_UNIT } = serving.settings ?? {};
  return `${new Intl.NumberFormat().format(serving.size)} ${volumeUnit}`;
};

export const getServingPriceLabel = (serving: Serving, showCurrency = false) => {
  if (!showCurrency) return serving.price;
  const { currency } = serving.settings ?? {};
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency ?? DEFAULT_CURRENCY,
    currencyDisplay: 'narrowSymbol'
  }).format(serving.price);
};

export const getVesselLabel = (vessel: Vessel, volumeUnit: string): string => {
  const type = VESSEL_PER_NAME[vessel.name] ? VESSEL_PER_NAME[vessel.name].type : 'Custom';
  const unitLabel = vessel.size
    ? ` (${new Intl.NumberFormat().format(resolveVesselSize({ ...vessel, type }, volumeUnit))} ${volumeUnit})`
    : '';
  return `${vessel.name}${unitLabel}`;
};

export const resolveVesselName = (vesselValue: string, userVessels: Vessel[] = []): string => {
  return VESSEL_PER_NAME[vesselValue]?.name || userVessels.find(({ name }) => name === vesselValue)?.name || vesselValue;
};

export const resolveVesselSize = (vessel: Vessel, volumeUnit: string): number => {
  const vesselData = vessel.type === 'Custom' ? { size: vessel.size } : VESSEL_PER_NAME[vessel.name];
  return vesselData ? volumeUnitConverter(volumeUnit, vesselData.size) : 0;
};
