import React from 'react';

import { useTranslation } from 'react-i18next';
import { Button } from '../../components/shared/Button';

export const LocationCard = ({ location, onEdit }) => {
  const { t } = useTranslation();

  return (
    <div className="flex w-[300px] border border-accent-grey p-6 rounded-md flex-col">
      <div className="flex flex-col gap-6">
        <h1 className="text-xl font-bold">{location.name}</h1>
        <div>
          <h3 className="text-light-text text-sm">{t('LocationCard.DefaultCurrency')}</h3>
          <span className="text-xl text-text">{location?.servingSettings?.currency}</span>
        </div>

        <div>
          <h3 className="text-light-text text-sm">{t('LocationCard.DefaultVolumeUnit')}</h3>
          <span className="text-xl text-text">{location?.servingSettings?.volumeUnit}</span>
        </div>
      </div>
      <div className="flex justify-end">
        <Button variant="secondary" size="sm" onClick={onEdit}>
          {t('LocationCard.Edit')}
        </Button>
      </div>
    </div>
  );
};
