import React from 'react';
import CheckIcon from '../../images/icon-check';

interface CheckboxProps {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  label?: string;
  disabled?: boolean;
  className?: string;
  id?: string;
  name?: string;
  required?: boolean;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  onChange,
  label,
  disabled = false,
  className = '',
  id,
  name,
  required = false
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <label
      className={`flex-nowrap inline-flex items-center cursor-pointer ${disabled ? 'opacity-50 cursor-not-allowed' : ''} ${className}`}>
      <div className="relative">
        <input
          type="checkbox"
          className="sr-only peer"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          id={id}
          name={name}
          required={required}
        />
        <div
          className={`
          w-5 h-5 
          rounded 
          border-2
          transition-all 
          peer-checked:bg-green 
          peer-checked:border-green
          ${disabled ? 'border-accent-grey' : checked ? 'border-green' : 'border-dark-grey'}
          peer-focus:ring-2 
          peer-focus:ring-hover-green
        `}>
          {checked && <CheckIcon className="w-6 h-6 text-white absolute -left-0.5 -top-0.5" />}
        </div>
      </div>
      {label && <span className="ml-2 text-md text-dark-grey text-nowrap whitespace-nowrap">{label}</span>}
    </label>
  );
};
