import React, { useContext, useEffect, useState } from 'react';
import { Button, Grid, Hidden, Snackbar, TextField, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { useLazyQuery } from 'react-apollo';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Logo from '../../images/pourwall-logo.svg';
import Illustration from '../../images/illustration-login.svg';
import Glassware from '../../images/glassware.svg';

import { ArrowForward } from '@material-ui/icons';

import { SessionContext } from '../../contexts/session';
import { GET_USER, LOGIN } from './queries';
import style from './styles';
import { LanguageDropdown } from '../../components/LanguageDropdown';
import CustomLink from '../../components/CustomLink';

const useStyles = makeStyles(style);

export const Login = ({ history }) => {
  const { t } = useTranslation();
  let params = useParams();
  let authToken = params?.token,
    tokenUserId = params?.tokenUser;

  const classes = useStyles();
  let { user, token, login } = useContext(SessionContext);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [hasSeenError, setHasSeenError] = useState(false);
  const [open, setOpen] = useState(false);

  const [doLogin, { loading, error, data }] = useLazyQuery(LOGIN);
  const [getUserQuery, { data: authTokenUserData }] = useLazyQuery(GET_USER);

  const go = (e) => {
    e.preventDefault();

    setOpen(false);
    setHasSeenError(false);

    if (email && password) {
      doLogin({ variables: { email, password } });
    }
  };

  if (error && !hasSeenError) {
    setOpen(true);
    setHasSeenError(true);
  }

  useEffect(() => {
    if (data?.login?.token && data?.login?.user) {
      login(data.login.user, data.login.token);
      history.push('/taps');
    } else if (authToken && authTokenUserData?.user) {
      login(authTokenUserData.user, authToken);
      history.push('/taps');
    }
  }, [data, history, login, authToken, authTokenUserData]);

  useEffect(() => {
    if (authToken && tokenUserId) {
      getUserQuery({ variables: { id: tokenUserId }, context: { headers: { authorization: authToken } } });
    }
  }, [authToken, tokenUserId, getUserQuery]);

  const handleClose = () => {
    setOpen(false);
  };

  if (user && token) return <Redirect to="/taps" />;

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item className={classes.header} xs={12} md={8}>
        <img src={Logo} alt="POURWALL" className={classes.logo} />
        <Hidden mdUp>
          <Typography variant="h1">{t('Login.WelcomeBack')}</Typography>
          <p>{t('Login.SignInToGetStarted')}</p>
        </Hidden>
        <Hidden smDown>
          <div className={classes.product}>
            <div className={classes.productText}>
              <img alt="" src={Glassware} className={classes.glassware} />
              <h1 className={classes.productHeading}>{t('Login.SimpleDigitalBeverageMenus')}</h1>
              <p className={classes.productDescription}>{t('Login.CreatedForBars')}</p>
            </div>
            <img alt="" src={Illustration} className={classes.barScene} />
          </div>
        </Hidden>
      </Grid>
      <Grid item className={classes.content} xs={12} md={4}>
        <Hidden smDown>
          <Typography variant="h1">{t('Login.WelcomeBack')}</Typography>
          <p>{t('Login.SignInToGetStarted')}</p>
        </Hidden>
        <form className={classes.form} onSubmit={go}>
          <TextField
            variant="outlined"
            fullWidth
            label={t('Login.Email')}
            className={classes.input}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            fullWidth
            label={t('Login.Password')}
            type="password"
            className={classes.input}
            onChange={(e) => setPassword(e.target.value)}
            onInput={(e) => setPassword(e.target.value)}
          />
          <p style={{ margin: 0 }}>
            {t('Login.ForgotYourPassword')} <CustomLink href="/forgotpassword">{t('Login.ResetIt')}</CustomLink>.
          </p>
          <Button type="submit" className={classes.button} variant="contained" color="primary" disabled={loading}>
            {t('Login.LetsGo')} <ArrowForward />
          </Button>
        </form>
        <div>
          <p className="mb-4">
            {t('Login.DontHaveAnAccountYet')} <CustomLink href="/signup">{t('Login.SignUp')}</CustomLink>.
          </p>
          <LanguageDropdown />
        </div>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert variant="filled" onClose={handleClose} severity="error">
          {t('Login.PleaseCheckEmailOrPassword')}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
