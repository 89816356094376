interface BinIconProps {
  className?: string;
}

const BinIcon = ({ className }: BinIconProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.64286 17.3438C9.64286 17.6703 9.37768 17.9375 9.05357 17.9375C8.72946 17.9375 8.46429 17.6703 8.46429 17.3438V9.625C8.46429 9.29844 8.72946 9.03125 9.05357 9.03125C9.37768 9.03125 9.64286 9.29844 9.64286 9.625V17.3438ZM12.5893 17.3438C12.5893 17.6703 12.3241 17.9375 12 17.9375C11.6759 17.9375 11.4107 17.6703 11.4107 17.3438V9.625C11.4107 9.29844 11.6759 9.03125 12 9.03125C12.3241 9.03125 12.5893 9.29844 12.5893 9.625V17.3438ZM15.5357 17.3438C15.5357 17.6703 15.2705 17.9375 14.9464 17.9375C14.6223 17.9375 14.3571 17.6703 14.3571 17.3438V9.625C14.3571 9.29844 14.6223 9.03125 14.9464 9.03125C15.2705 9.03125 15.5357 9.29844 15.5357 9.625V17.3438ZM15.4436 3.42551L16.7953 5.46875H19.3661C19.8559 5.46875 20.25 5.86768 20.25 6.35938C20.25 6.85293 19.8559 7.25 19.3661 7.25H19.0714V18.5312C19.0714 20.1715 17.7529 21.5 16.125 21.5H7.875C6.24783 21.5 4.92857 20.1715 4.92857 18.5312V7.25H4.63393C4.14593 7.25 3.75 6.85293 3.75 6.35938C3.75 5.86768 4.14593 5.46875 4.63393 5.46875H7.20542L8.55636 3.42551C8.9394 2.84723 9.58393 2.5 10.2727 2.5H13.7273C14.4161 2.5 15.0606 2.84727 15.4436 3.42551ZM9.3298 5.46875H14.6702L13.9704 4.41336C13.9152 4.33098 13.8231 4.28125 13.7273 4.28125H10.2727C10.1769 4.28125 10.0517 4.33098 10.0296 4.41336L9.3298 5.46875ZM6.69643 18.5312C6.69643 19.1881 7.22421 19.7188 7.875 19.7188H16.125C16.7769 19.7188 17.3036 19.1881 17.3036 18.5312V7.25H6.69643V18.5312Z"
      fill="currentColor"
    />
  </svg>
);

export default BinIcon;
