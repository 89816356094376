import { LinearProgress } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../../contexts/session';

import { FeedbackBar } from '../../../components/FeedbackBar';
import { useFileUpload } from '../../../hooks/useFileUpload';

import { checkFileSize, MAX_SCREEN_IMAGE_SIZE_BYTES, uploadToAws } from '../../../util/files';
import { SIGN_FILE_FOR_UPLOAD } from '../mutations';

import { Themes } from './themes';

import { Tab } from '../../../components/shared/Tab';
import { Tabs } from '../../../components/shared/Tabs';
import ArrowIcon from '../../../images/icon-arrow';
import { PageTypeSelection } from './components/PageTypeSelection';

import { useHistory } from 'react-router-dom';
import { Alert } from '../../../components/shared/Alert';
import { Button } from '../../../components/shared/Button';
import { Dropdown } from '../../../components/shared/Dropdown';
import ArrowCurvedUpIcon from '../../../images/icon-arrow-curved-up';
import { UploadImage } from '../UploadImage';
import { PageInfo } from './components/PageInfo';
import { PageStyleCopyFrom } from './components/PageStyleCopyFrom';
import { PageStyleCustom } from './components/PageStyleCustom';
import { PageStyleTheme } from './components/PageStyleTheme';
import { Checkbox } from '../../../components/shared/Checkbox';

export const PageEditForm = ({ screen, page, onCancel, onSave, onDelete }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const { currentLocation, premium } = session;

  const [interstitialType, setInterstitialType] = useState(page.type);
  const [error, setError] = useState(null);

  const [number, setNumber] = useState(page.number);
  const [name, setName] = useState(page.name);
  const [type, setType] = useState(page.type);
  const [rotationDuration, setRotationDuration] = useState(page.rotationDuration);
  const [tapStart, setTapStart] = useState(page.tapStart);
  const [tapEnd, setTapEnd] = useState(page.tapEnd);

  // style related fields
  const [styleCategory, setStyleCategory] = useState(page.style?.pageKey ? 'Copy From...' : page.style?.theme ? 'Select Theme' : 'Custom');
  const [stylePageKey, setStylePageKey] = useState(page.style?.pageKey);
  const [styleThemeName, setStyleThemeName] = useState(page.style?.theme);

  const themeOptions = page.style?.themeOptions || {};

  const [menuType, setMenuType] = useState(themeOptions.menuType || 'normal');
  const [backgroundColor, setBackgroundColor] = useState(themeOptions.backgroundColor || '#3c3c3c');
  const [foregroundColor, setForegroundColor] = useState(themeOptions.foregroundColor || '#efefef');
  const [foregroundColor2, setForegroundColor2] = useState(themeOptions.foregroundColor2 || '#f7e63b');
  const [textSize, setTextSize] = useState(themeOptions.textSize || 'medium');
  const [primaryFont, setPrimaryFont] = useState(themeOptions.primaryFont || 'Open Sans');
  const [secondaryFont, setSecondaryFont] = useState(themeOptions.secondaryFont || 'Unica One');
  const [showTapNumber, setShowTapNumber] = useState(themeOptions.showTapNumber === false ? false : true);
  const [showLocation, setShowLocation] = useState(themeOptions.showLocation || false);
  const [showBeverageImage, setShowBeverageImage] = useState(themeOptions.showBeverageImage || false);
  const [reverseBreweryAndName, setReverseBreweryAndName] = useState(themeOptions.reverseBreweryAndName || false);
  const [beverageImageSize, setBeverageImageSize] = useState(themeOptions.beverageImageSize || 'none');
  const [hideBeverageSourceName, setHideBeverageSourceName] = useState(themeOptions.hideBeverageSourceName || false);
  //const [showRating, setShowRating] = useState(themeOptions.showRating || false);
  const [showHeading, setShowHeading] = useState(themeOptions.showHeading || false);
  const [showLogo, setShowLogo] = useState(themeOptions.showLogo === false ? false : true);
  const [showServings, setShowServings] = useState(themeOptions.showServings === false ? false : true);
  const [showStripes, setShowStripes] = useState(themeOptions.showStripes || false);
  const [showServingSize, setShowServingSize] = useState(themeOptions.showServingSize || false);
  const [showServingCurrency, setShowServingCurrency] = useState(themeOptions.showServingCurrency || false);
  const [showTapsDescription, setShowTapsDescription] = useState(themeOptions.showTapsDescription || false);
  const [columns, setColumns] = useState(themeOptions.columns || 2);
  const [tapsOrientation, setTapsOrientation] = useState(themeOptions.tapsOrientation || 'left_to_right');
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(themeOptions.backgroundImageUrl);
  const [backgroundImagePreviewUrl, setBackgroundImagePreviewUrl] = useState();
  const [backgroundImageFile, setBackgroundImageFile] = useState();
  const [backgroundImageFileSignedUploadUrl, setBackgroundImageFileSignedUploadUrl] = useState();
  const [showBackgroundImageHelper, setShowBackgroundImageHelper] = useState(themeOptions.showBackgroundImageHelper || false);
  const [kegMonitoringPartner, setKegMonitoringPartner] = useState(themeOptions.kegMonitoringPartner || 'none');
  const [kegMonitoringApiKey, setKegMonitoringApiKey] = useState(themeOptions.kegMonitoringApiKey || '');

  const [loading, setLoading] = useState(false);

  const [signFile] = useMutation(SIGN_FILE_FOR_UPLOAD);

  const fileUploadHook = useFileUpload({
    maxFileSize: MAX_SCREEN_IMAGE_SIZE_BYTES,
    locationName: currentLocation?.shortName
  });

  const { error: imageError, resourceUrl: imageUrl, file: imageFile, signedAwsUrl } = fileUploadHook;

  const handleCloseThemeCategoryMenu = (category) => {
    if (category) {
      setStyleCategory(category);
      if (category === 'Custom') {
        setStylePageKey('');
        setStyleThemeName('');
      } else if (category === 'Select Theme') {
        setStylePageKey('');
      } else if (category === 'Copy From...') {
        setStyleThemeName('');
      }
    }
  };

  const selectTheme = (theme) => {
    setStyleThemeName(theme.name);
    setMenuType(theme.themeOptions.menuType);
    setBackgroundImageUrl(theme.themeOptions.backgroundImageUrl);
    setBackgroundColor(theme.themeOptions.backgroundColor);
    setForegroundColor(theme.themeOptions.foregroundColor);
    setForegroundColor2(theme.themeOptions.foregroundColor2);
    setShowBeverageImage(theme.themeOptions.showBeverageImage);
    setBeverageImageSize(theme.themeOptions.beverageImageSize);
    setShowHeading(theme.themeOptions.showHeading);
    setShowLogo(theme.themeOptions.showLogo);
    setShowServings(theme.themeOptions.showServings);
    setShowStripes(theme.themeOptions.showStripes);
    setShowTapNumber(theme.themeOptions.showTapNumber);
    setShowLocation(theme.themeOptions.showLocation);
    setShowServingSize(theme.themeOptions.showServingSize);
    setShowServingCurrency(theme.themeOptions.showServingCurrency);
    setShowTapsDescription(theme.themeOptions.showTapsDescription);
    setColumns(theme.themeOptions.columns);
    setHideBeverageSourceName(theme.themeOptions.hideBeverageSourceName);
    setReverseBreweryAndName(theme.themeOptions.reverseBreweryAndName);
    setTextSize(theme.themeOptions.textSize);
    setShowBackgroundImageHelper(theme.themeOptions.showBackgroundImageHelper);
    // TODO: most themes don't have fonts defined yet
    setPrimaryFont(theme.themeOptions.primaryFont || primaryFont);
    setSecondaryFont(theme.themeOptions.secondaryFont || secondaryFont);
  };

  const confirmType = () => {
    setType(interstitialType);
    if (interstitialType === 'taps') {
      setTapStart(1);
      setTapEnd(10);
    }
  };

  const handleBackgroundImageSelection = async (file, previewUrl) => {
    //The line below is a workaround to show the error messages everytime a file is chosen.
    setError('');
    const fileSizeError = checkFileSize(file, MAX_SCREEN_IMAGE_SIZE_BYTES);
    if (fileSizeError) {
      setError(fileSizeError.message);
    } else {
      setBackgroundImagePreviewUrl(previewUrl);
      const variables = {
        locationName: currentLocation?.shortName,
        fileName: file.name,
        fileType: file.type
      };

      const { data, error } = await signFile({ variables });
      if (error) {
        setError(error);
      }
      if (data?.signFile) {
        setBackgroundImageFile(file);
        setBackgroundImageFileSignedUploadUrl(data.signFile.signedUrl);
        setBackgroundImageUrl(data.signFile.resourceUrl);
      }
    }
  };

  const removeBackgroundImage = () => {
    setBackgroundImagePreviewUrl(null);
    setBackgroundImageUrl(null);
    setBackgroundImageFileSignedUploadUrl(null);
    setBackgroundImageFile(null);
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      if (imageFile && signedAwsUrl) {
        try {
          await uploadToAws(signedAwsUrl, imageFile);
        } catch (err) {
          setError(err instanceof Error ? err : new Error('Unknown error occurred'));
          setLoading(false);
        }
      }

      if (backgroundImageFile && backgroundImageFileSignedUploadUrl) {
        try {
          await uploadToAws(backgroundImageFileSignedUploadUrl, backgroundImageFile);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      }

      // construct the style object
      const style = {
        pageKey: stylePageKey,
        theme: styleThemeName,
        themeOptions: {
          backgroundColor,
          foregroundColor,
          foregroundColor2,
          textSize,
          showHeading,
          showLogo,
          //showRating,
          menuType,
          showBeverageImage,
          beverageImageSize,
          showTapNumber,
          showLocation,
          showServings,
          showTapsDescription,
          hideBeverageSourceName,
          reverseBreweryAndName,
          showStripes,
          columns,
          backgroundImageUrl,
          showBackgroundImageHelper,
          primaryFont,
          secondaryFont,
          showServingSize,
          showServingCurrency,
          kegMonitoringPartner,
          kegMonitoringApiKey,
          tapsOrientation
        }
      };

      // prepare and return the updated page
      let updatedPage = {
        ...page,
        number,
        name,
        type,
        tapStart,
        tapEnd,
        rotationDuration,
        style,
        imageUrl
      };

      onSave(updatedPage);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    onDelete();
  };

  const styleCustomProps = {
    menuType,
    setMenuType,
    columns,
    setColumns,
    primaryFont,
    setPrimaryFont,
    secondaryFont,
    setSecondaryFont,
    backgroundColor,
    setBackgroundColor,
    foregroundColor,
    setForegroundColor,
    foregroundColor2,
    setForegroundColor2,
    textSize,
    setTextSize,
    tapsOrientation,
    setTapsOrientation,
    setReverseBreweryAndName,
    kegMonitoringPartner,
    setKegMonitoringPartner,
    kegMonitoringApiKey,
    setKegMonitoringApiKey,
    showHeading,
    setShowHeading,
    showTapNumber,
    setShowTapNumber,
    hideBeverageSourceName,
    setHideBeverageSourceName,
    reverseBreweryAndName,
    showServings,
    setShowServings,
    showServingSize,
    setShowServingSize,
    showServingCurrency,
    setShowServingCurrency,
    showStripes,
    setShowStripes,
    showLocation,
    setShowLocation,
    showTapsDescription,
    setShowTapsDescription,
    premium,
    showLogo,
    setShowLogo,
    beverageImageSize,
    setBeverageImageSize,
    setShowBeverageImage,
    backgroundImageUrl,
    backgroundImagePreviewUrl,
    showBackgroundImageHelper,
    setShowBackgroundImageHelper,
    handleBackgroundImageSelection,
    removeBackgroundImage
  };

  return (
    <>
      <FeedbackBar message={error || imageError} />
      {!type && (
        <>
          <div className="pl-4 mb-6 flex items-center border-b border-accent-grey pb-4">
            <ArrowBack className="cursor-pointer" onClick={() => history.push(`/screens/${screen._id}/edit`)} />
          </div>
          <PageTypeSelection interstitialType={interstitialType} setInterstitialType={setInterstitialType} onConfirm={confirmType} />
        </>
      )}

      {type && (
        <>
          {type === 'image' && (
            <>
              <div className="col-span-2 px-3 pb-3 border-b">
                <ArrowBack onClick={() => onCancel()} className="cursor-pointer" />
              </div>

              <PageInfo
                name={name}
                setName={setName}
                number={number}
                setNumber={setNumber}
                rotationDuration={rotationDuration}
                setRotationDuration={setRotationDuration}
                tapStart={tapStart}
                setTapStart={setTapStart}
                tapEnd={tapEnd}
                setTapEnd={setTapEnd}
                hideTapListInputs
              />

              {premium && (
                <div className="col-span-12 md:col-span-12 px-6">
                  <Checkbox label={t('PageEditForm.ShowLogo')} checked={showLogo} onChange={(checked) => setShowLogo(checked)} />
                </div>
              )}

              <UploadImage {...fileUploadHook} previewUrl={fileUploadHook.previewUrl || page?.imageUrl} />
            </>
          )}
          {type === 'taps' && (
            <Tabs leftIcon={<ArrowIcon className="cursor-pointer self-center" />} onLeftIconClick={onCancel}>
              <Tab label={t('PageEditForm.PageInfoTab')}>
                <PageInfo
                  name={name}
                  setName={setName}
                  number={number}
                  setNumber={setNumber}
                  rotationDuration={rotationDuration}
                  setRotationDuration={setRotationDuration}
                  tapStart={tapStart}
                  setTapStart={setTapStart}
                  tapEnd={tapEnd}
                  setTapEnd={setTapEnd}
                />
              </Tab>
              <Tab className="relative" label={t('PageEditForm.StyleTab')}>
                <div className="absolute -top-12 right-4 flex items-center justify-end gap-4">
                  <Dropdown
                    label={styleCategory}
                    options={[
                      { value: 'Custom', label: t('PageEditForm.Custom') },
                      { value: 'Copy From...', label: t('PageEditForm.CopyFrom') },
                      { value: 'Select Theme', label: t('PageEditForm.SelectTheme') }
                    ]}
                    onChange={(value) => handleCloseThemeCategoryMenu(value)}
                    buttonProps={{ variant: 'secondary', rounded: true }}
                    align="right"
                  />
                </div>
                {styleCategory === 'Custom' && (
                  <Alert className="text-center ">
                    <div className="flex justify-center">
                      {t('PageEditForm.StyleDropdown')}
                      <div className="hidden md:block w-32 lg:w-28" />
                      <ArrowCurvedUpIcon className="hidden md:block absolute right-20 scale-x-[-1]" />
                    </div>
                  </Alert>
                )}
                <div className="p-6">
                  {styleCategory === 'Custom' && <PageStyleCustom {...styleCustomProps} />}
                  {styleCategory === 'Copy From...' && (
                    <PageStyleCopyFrom screen={screen} page={page} stylePageKey={stylePageKey} setStylePageKey={setStylePageKey} />
                  )}
                  {styleCategory === 'Select Theme' && (
                    <PageStyleTheme Themes={Themes} styleThemeName={styleThemeName} selectTheme={selectTheme} />
                  )}
                </div>
              </Tab>
            </Tabs>
          )}

          <div className="border-t gap-2 p-4 flex justify-end items-center">
            {loading && <LinearProgress />}
            <div className="flex gap-2 justify-end items-center">
              <Button variant="destructive" onClick={handleDelete} disabled={loading}>
                {t('PageEditForm.Delete')}
              </Button>
              <Button onClick={handleSave} disabled={loading}>
                {t('PageEditForm.Save')}
              </Button>
            </div>
          </div>
        </>
      )}
    </>
  );
};
