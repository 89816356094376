import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { PageEditForm } from './PageEditForm';
import { GET_SCREEN_BY_ID } from '../queries';
import { Loading } from '../../../components/Loading';
import { FeedbackBar } from '../../../components/FeedbackBar';
import { PageEditFormLayout } from './PageEditFormLayout';
import { usePageActions } from './hooks/usePageActions';

interface RouteParams {
  screen_id: string;
  page_key: string;
}

export const PageEdit = () => {
  const { screen_id, page_key } = useParams<RouteParams>();
  const history = useHistory();

  const { data, loading, error } = useQuery(GET_SCREEN_BY_ID, {
    variables: { id: screen_id }
  });

  const { handleSavePage, handleDeletePage } = usePageActions(data?.screen);

  if (loading) return <Loading />;
  if (error) return <FeedbackBar message={error.message} />;

  const screen = data?.screen;
  const page = screen?.pages.find((p: any) => p.key === page_key);

  if (!page) {
    history.push(`/screens/${screen_id}/edit`);
    return null;
  }

  const handleSave = async (updatedPage: any) => {
    await handleSavePage(updatedPage);
    history.push(`/screens/${screen_id}/edit`);
  };

  const handleDelete = async () => {
    await handleDeletePage(page.key);
    history.push(`/screens/${screen_id}/edit`);
  };

  return (
    <PageEditFormLayout>
      <PageEditForm
        screen={screen}
        page={page}
        onSave={handleSave}
        onCancel={() => history.push(`/screens/${screen_id}/edit`)}
        onDelete={handleDelete}
      />
    </PageEditFormLayout>
  );
};
