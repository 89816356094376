import { useState, useEffect, createRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { GET_TAPS } from '../queries';
import { useSession } from '../../../contexts/session';
import { sortBy } from 'lodash';
import { Loading } from '../../../components/Loading';
import { FeedbackBar } from '../../../components/FeedbackBar';
import shortid from 'shortid';
import { EditServingForm } from './EditServingDialog';
import { Button } from '../../../components/shared/Button';
import { Input } from '../../../components/shared/Input';
import { Modal } from '../../../components/shared/Modal';
import ArrowIcon from '../../../images/icon-arrow';
export const CopyServingsDialog = ({ currentTap, open, onClose, onCopyServings }) => {
  const { t } = useTranslation();
  const [selectedTap, setSelectedTap] = useState(null);
  const [servingsToEdit, setServingsToEdit] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const { currentLocation } = useSession();

  const { data, loading, error } = useQuery(GET_TAPS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network'
  });

  const taps = useMemo(
    () => sortBy(data?.taps?.filter((tap) => tap.servings?.length > 0 && currentTap?._id !== tap._id) || [], (t) => t.name),
    [currentTap, data]
  );

  useEffect(() => {
    if (selectedTap) {
      setServingsToEdit(
        selectedTap.servings.map(({ vessel, size, price, volumeUnit, currency }, index) => ({
          title: `${t('CopyServingsDialog.Serving')} ${index + 1}`,
          ref: createRef(),
          serving: {
            key: shortid.generate(),
            vessel: vessel,
            size,
            price,
            settings: { volumeUnit, currency }
          }
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTap]);

  useEffect(() => {
    if (isDeleting) {
      setIsDeleting(false);
    }
  }, [isDeleting]);

  const handleCopy = async () => {
    let updatedServings = [];
    let hasError = false;
    for (let i = 0; i < servingsToEdit.length; i++) {
      const ref = servingsToEdit[i].ref;
      const updatedItem = await ref.current.handleSave();
      if (updatedItem) {
        updatedServings.push(updatedItem);
      } else {
        hasError = true;
      }
    }
    if (!hasError) {
      onCopyServings(updatedServings);
      handleClose();
    }
  };

  const handleClose = () => {
    setSelectedTap(null);
    setIsDeleting(false);
    setServingsToEdit([]);
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <FeedbackBar message={error} />
      <div className="p-4 pb-16 pt-0 text-dark-grey">
        <div className="sticky py-4 top-0 z-10 bg-white flex items-center hover:cursor-pointer" onClick={onClose}>
          <ArrowIcon />
          <div className="flex-grow text-center font-bold uppercase text-lg ">{t('CopyServingsDialog.Title').toUpperCase()}</div>
        </div>

        <div>
          {loading ? (
            <Loading className="my-10" />
          ) : (
            <>
              <div className="mt-1 mb-4 font-bold uppercase">{t('CopyServingsDialog.SelectTapLabel')}</div>
              <Input
                label={t('CopyServingsDialog.SelectTap')}
                value={selectedTap}
                options={taps.map((tap) => ({ value: tap, label: tap.beverage.name }))}
                onOptionSelect={(value) => setSelectedTap(value)}
                searchable
              />
            </>
          )}
        </div>
        {servingsToEdit.length > 0 && <div className="border-b-1 border-accent-grey mt-5" />}
        <div className="p-6">
          <div className="flex flex-col space-y-4">
            {selectedTap &&
              !isDeleting &&
              servingsToEdit.map((servingToEdit) => (
                <div key={servingToEdit.key} className="mb-4">
                  <div className="flex justify-between items-center">
                    <div className="font-bold uppercase">{servingToEdit.title}</div>
                    <Button
                      size={'sm'}
                      variant="destructive"
                      onClick={() => {
                        setIsDeleting(true);
                        setServingsToEdit(servingsToEdit.filter((s) => s.serving.key !== servingToEdit.serving.key));
                      }}>
                      {t('CopyServingsDialog.Delete')}
                    </Button>
                  </div>
                  <div className="-m-6">
                    <EditServingForm serving={servingToEdit.serving} ref={servingToEdit.ref} showActions={false} />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 w-full bg-white p-4 flex justify-end gap-x-2 z-10">
        <Button onClick={handleClose} variant="secondary">
          {t('CopyServingsDialog.Cancel')}
        </Button>
        <Button onClick={handleCopy} disabled={servingsToEdit.length === 0} color="primary" variant="primary">
          {t('CopyServingsDialog.CopyServings')}
        </Button>
      </div>
    </Modal>
  );
};
