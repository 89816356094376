interface Props {
  className?: string;
}

const QuestionIcon = ({ className }: Props) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.96732 12C8.20065 12 8.39787 11.9195 8.55898 11.7584C8.7201 11.5973 8.80065 11.4 8.80065 11.1667C8.80065 10.9334 8.7201 10.7362 8.55898 10.575C8.39787 10.4139 8.20065 10.3334 7.96732 10.3334C7.73398 10.3334 7.53676 10.4139 7.37565 10.575C7.21454 10.7362 7.13398 10.9334 7.13398 11.1667C7.13398 11.4 7.21454 11.5973 7.37565 11.7584C7.53676 11.9195 7.73398 12 7.96732 12ZM7.36732 9.43337H8.60065C8.60065 9.06671 8.64232 8.77782 8.72565 8.56671C8.80898 8.3556 9.0451 8.06671 9.43398 7.70004C9.72287 7.41115 9.95065 7.13615 10.1173 6.87504C10.284 6.61393 10.3673 6.30004 10.3673 5.93337C10.3673 5.31115 10.1395 4.83337 9.68398 4.50004C9.22843 4.16671 8.68954 4.00004 8.06732 4.00004C7.43398 4.00004 6.9201 4.16671 6.52565 4.50004C6.13121 4.83337 5.85621 5.23337 5.70065 5.70004L6.80065 6.13337C6.85621 5.93337 6.98121 5.71671 7.17565 5.48337C7.3701 5.25004 7.66732 5.13337 8.06732 5.13337C8.42287 5.13337 8.68954 5.2306 8.86732 5.42504C9.0451 5.61949 9.13398 5.83337 9.13398 6.06671C9.13398 6.28893 9.06732 6.49726 8.93398 6.69171C8.80065 6.88615 8.63398 7.06671 8.43398 7.23337C7.9451 7.66671 7.6451 7.99449 7.53398 8.21671C7.42287 8.43893 7.36732 8.84449 7.36732 9.43337ZM8.00065 14.6667C7.07843 14.6667 6.21176 14.4917 5.40065 14.1417C4.58954 13.7917 3.88398 13.3167 3.28398 12.7167C2.68398 12.1167 2.20898 11.4112 1.85898 10.6C1.50898 9.78893 1.33398 8.92226 1.33398 8.00004C1.33398 7.07782 1.50898 6.21115 1.85898 5.40004C2.20898 4.58893 2.68398 3.88337 3.28398 3.28337C3.88398 2.68337 4.58954 2.20837 5.40065 1.85837C6.21176 1.50837 7.07843 1.33337 8.00065 1.33337C8.92287 1.33337 9.78954 1.50837 10.6007 1.85837C11.4118 2.20837 12.1173 2.68337 12.7173 3.28337C13.3173 3.88337 13.7923 4.58893 14.1423 5.40004C14.4923 6.21115 14.6673 7.07782 14.6673 8.00004C14.6673 8.92226 14.4923 9.78893 14.1423 10.6C13.7923 11.4112 13.3173 12.1167 12.7173 12.7167C12.1173 13.3167 11.4118 13.7917 10.6007 14.1417C9.78954 14.4917 8.92287 14.6667 8.00065 14.6667Z"
      fill="currentColor"
    />
  </svg>
);

export default QuestionIcon;
