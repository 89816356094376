import { useTranslation } from 'react-i18next';
import { Button } from '../../components/shared/Button';

export const MemberCard = ({
  member,
  onManageLocations,
  deleteMemberLoading,
  onRemove
}: {
  member: any;
  deleteMemberLoading: boolean;
  onManageLocations: () => void;
  onRemove: () => void;
}) => {
  const { t } = useTranslation();
  const { user, role } = member;
  const isActive = !!user.metadata.length;

  const OWNER = 'Owner';
  const MEMBER = 'Member';
  const isOwner = role === 'owner';
  const i18nOwnerText = `Account.${isOwner ? OWNER : MEMBER}`;

  return (
    <div className="flex w-[300px] border border-accent-grey p-6 rounded-md flex-col">
      <div className="flex flex-col gap-6">
        <h1 className="text-xl font-bold">{`${user.firstName} ${user.lastName ?? ''}`}</h1>
        <div className="flex gap-8">
          <div>
            <h3 className="text-light-text text-sm">{t('Account.Status')}</h3>
            <span className="text-xl text-text">{t(`Account.${isActive ? 'Active' : 'Invited'}`)}</span>
          </div>
          <div>
            <h3 className="text-light-text text-sm">{t('Account.Role')}</h3>
            <span className="text-xl text-text">{t(i18nOwnerText)}</span>
          </div>
        </div>
        <div>
          <h3 className="text-light-text text-sm">{t('Account.Email')}</h3>
          <span className="text-xl text-text">{user.local.email}</span>
        </div>
      </div>
      <div className="flex justify-end gap-4 pt-4">
        {!isOwner && (
          <Button variant="secondary" size="sm" onClick={onManageLocations}>
            {t('Account.ManageLocations')}
          </Button>
        )}
        <Button variant="destructive" disabled={deleteMemberLoading} size="sm" onClick={onRemove}>
          {t('Account.Remove')}
        </Button>
      </div>
    </div>
  );
};
