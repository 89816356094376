import React from 'react';
import { Input } from './Input';

interface Props {
  value: string;
  label: string;
  onChange: (value: string) => void;
  className?: string;
  containerClassName?: string;
}

export const FontPicker = ({ value, label, onChange, className, containerClassName }: Props) => {
  return (
    <Input
      label={label}
      value={value}
      onOptionSelect={(value) => onChange(value as string)}
      options={[
        { value: 'Open Sans', label: 'Open Sans', className: 'font-open-sans' },
        { value: 'Unica One', label: 'Unica One', className: 'font-unica' },
        { value: 'Inter', label: 'Inter', className: 'font-inter' },
        { value: 'Oswald', label: 'Oswald', className: 'font-oswald' },
        { value: 'Anton', label: 'Anton', className: 'font-anton' },
        { value: 'Comfortaa', label: 'Comfortaa', className: 'font-comfortaa' },
        { value: 'Fjalla One', label: 'Fjalla One', className: 'font-fjalla' }
      ]}
      className={className}
      containerClassName={containerClassName}
    />
  );
};
