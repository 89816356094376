import React from 'react';
import { Container, Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  actions: {
    background: 'white',
    position: 'fixed',
    bottom: '0',
    padding: '15px',
    left: '0',
    right: '0',
    boxShadow: '0 -1px 4px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,

    [theme.breakpoints.down('sm')]: {
      position: 'fixed'
    }
  },
  dialog: {
    position: 'absolute'
  }
}));

export const ActionBar = ({ dialog, loading, className = '', ...props }) => {
  const classes = useStyles();

  return (
    <Container maxWidth="md" className={clsx(classes.actions, dialog ? classes.dialog : null, className)} {...props}>
      <Grid container direction="row">
        <Grid item xs={12}>
          {loading && <LinearProgress style={{ marginBottom: 10 }} />}
        </Grid>
        <Grid item xs={12} style={{ display: 'flex' }}>
          {props.children}
        </Grid>
      </Grid>
    </Container>
  );
};
