interface ExclamationIconProps {
  className?: string;
}

const ExclamationIcon = ({ className }: ExclamationIconProps) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 18.1888C10 17.9181 10.0517 17.6501 10.1522 17.4C10.2528 17.1499 10.4001 16.9227 10.5858 16.7313C10.7715 16.5399 10.992 16.3881 11.2346 16.2845C11.4773 16.1809 11.7374 16.1276 12 16.1276C12.2626 16.1276 12.5227 16.1809 12.7654 16.2845C13.008 16.3881 13.2285 16.5399 13.4142 16.7313C13.5999 16.9227 13.7472 17.1499 13.8478 17.4C13.9483 17.6501 14 17.9181 14 18.1888C14 18.7355 13.7893 19.2597 13.4142 19.6463C13.0391 20.0328 12.5304 20.25 12 20.25C11.4696 20.25 10.9609 20.0328 10.5858 19.6463C10.2107 19.2597 10 18.7355 10 18.1888ZM10.196 5.81121C10.1694 5.55117 10.1961 5.28827 10.2744 5.03955C10.3527 4.79084 10.4809 4.56187 10.6506 4.36751C10.8204 4.17315 11.0278 4.01774 11.2596 3.91136C11.4914 3.80497 11.7423 3.75 11.996 3.75C12.2497 3.75 12.5006 3.80497 12.7324 3.91136C12.9642 4.01774 13.1716 4.17315 13.3414 4.36751C13.5111 4.56187 13.6393 4.79084 13.7176 5.03955C13.7959 5.28827 13.8226 5.55117 13.796 5.81121L13.096 13.0399C13.0676 13.3201 12.9394 13.5796 12.7363 13.7682C12.5331 13.9568 12.2694 14.0612 11.996 14.0612C11.7226 14.0612 11.4589 13.9568 11.2557 13.7682C11.0526 13.5796 10.9244 13.3201 10.896 13.0399L10.196 5.81121Z"
      fill="currentColor"
    />
  </svg>
);

export default ExclamationIcon;
