interface Props {
  className?: string;
}

const PlusIcon = ({ className }: Props) => (
  <svg className={className} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0003 3.63635C9.49826 3.63635 9.09125 4.04337 9.09125 4.54544V9.09086H4.54581C4.04373 9.09086 3.63672 9.49787 3.63672 9.99995C3.63672 10.502 4.04373 10.909 4.54581 10.909H9.09125V15.4545C9.09125 15.9566 9.49826 16.3636 10.0003 16.3636C10.5024 16.3636 10.9094 15.9566 10.9094 15.4545V10.909H15.4549C15.957 10.909 16.364 10.502 16.364 9.99995C16.364 9.49787 15.957 9.09086 15.4549 9.09086H10.9094V4.54544C10.9094 4.04337 10.5024 3.63635 10.0003 3.63635Z"
      fill="currentColor"
    />
  </svg>
);

export default PlusIcon;
