import React from 'react';

interface CastIconProps {
  className?: string;
}

const CastIcon = ({ className }: CastIconProps) => (
  <svg className={className} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.666 16.6666H12.4994C12.4994 16.3889 12.4889 16.1111 12.4681 15.8333C12.4473 15.5555 12.416 15.2778 12.3744 15H16.666V4.99998H3.33268V5.95831C3.0549 5.91665 2.77713 5.8854 2.49935 5.86456C2.22157 5.84373 1.94379 5.83331 1.66602 5.83331V4.99998C1.66602 4.54165 1.82921 4.14929 2.1556 3.8229C2.48199 3.49651 2.87435 3.33331 3.33268 3.33331H16.666C17.1244 3.33331 17.5167 3.49651 17.8431 3.8229C18.1695 4.14929 18.3327 4.54165 18.3327 4.99998V15C18.3327 15.4583 18.1695 15.8507 17.8431 16.1771C17.5167 16.5035 17.1244 16.6666 16.666 16.6666ZM1.66602 16.6666V14.1666C2.36046 14.1666 2.95074 14.4097 3.43685 14.8958C3.92296 15.3819 4.16602 15.9722 4.16602 16.6666H1.66602ZM5.83268 16.6666C5.83268 15.5139 5.42643 14.5312 4.61393 13.7187C3.80143 12.9062 2.81879 12.5 1.66602 12.5V10.8333C3.29102 10.8333 4.66949 11.3993 5.80143 12.5312C6.93338 13.6632 7.49935 15.0416 7.49935 16.6666H5.83268ZM9.16602 16.6666C9.16602 15.625 8.9681 14.6493 8.57227 13.7396C8.17643 12.8298 7.64171 12.0382 6.9681 11.3646C6.29449 10.691 5.50282 10.1562 4.5931 9.7604C3.68338 9.36456 2.70768 9.16665 1.66602 9.16665V7.49998C2.9299 7.49998 4.1174 7.73956 5.22852 8.21873C6.33963 8.6979 7.31185 9.35415 8.14518 10.1875C8.97852 11.0208 9.63477 11.993 10.1139 13.1041C10.5931 14.2153 10.8327 15.4028 10.8327 16.6666H9.16602Z"
      fill="#124736"
    />
  </svg>
);

export default CastIcon;
