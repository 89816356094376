import React, { useEffect, type ReactNode } from 'react';
import ArrowIcon from '../../images/icon-arrow';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  children: ReactNode;
  title?: string;
  fullScreen?: boolean;
  className?: string;
}

export const Modal: React.FC<ModalProps> = ({ open, onClose, children, title, className }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex md:items-center md:justify-center font-public-sans">
      <div className="fixed inset-0 bg-black/50" onClick={onClose} />

      <div
        className={`relative flex flex-col bg-white w-full h-full overflow-hidden
        md:h-auto md:max-h-[90vh] md:w-auto md:max-w-[90vw] md:min-w-[400px] md:mx-4 md:rounded-lg md:shadow-xl
        ${className || ''}`}>
        {title && (
          <div className="flex items-center justify-center w-full p-4 border-b shrink-0">
            <button onClick={onClose} className="absolute left-4 p-1 rounded-full">
              <ArrowIcon className="text-dark-grey" />
            </button>
            <h2 className="text-lg text-dark-grey font-semibold">{title}</h2>
          </div>
        )}

        <div className="flex-1 overflow-y-auto min-h-0 rounded-b-lg">{children}</div>
      </div>
    </div>
  );
};
