import React, { useContext, useState } from 'react';
import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Add } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { camelCase, sortBy } from 'lodash';
import clsx from 'clsx';

import { SessionContext } from '../../contexts/session';

import { Layout } from '../../components/Layout';
import { FeedbackBar } from '../../components/FeedbackBar';
import { Loading } from '../../components/Loading';

import EmptyTapListImage from '../../images/taps.png';
import IconPremiumChip from '../../images/icon-premium.svg';

import { GET_TAPS } from './queries';
import { PrintDialog } from './print/PrintDialog';
import { EmbedDialog } from './embed/EmbedDialog';
import { QrCodeDialog } from './qrCode/QrCodeDialog';
import { UserVesselsProvider } from '../../contexts/userVessels';
import PremiumFeatureDialog from '../../components/PremiumFeatureDialog';
import CustomLink from '../../components/CustomLink';
import QrCodeIcon from '../../images/icon-qrcode';
import PrintIcon from '../../images/icon-print';
import EmbedIcon from '../../images/icon-embed';
import Tooltip from '../../components/Tooltip';
import SearchInput from '../../components/SearchInput';
import { TapList } from './TapList';
import { Button } from '../../components/shared/Button';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '& [aria-label="print"]': {
      height: 48,
      width: 48,
      marginRight: 10
    }
  },
  editDrawer: {
    minHeight: '400px',
    padding: '20px'
  },
  drawerPaper: {
    borderTopRightRadius: '40px',
    borderTopLeftRadius: '40px'
  },

  emptyListContainer: {
    padding: '80px 30px',
    textAlign: 'center',

    '& img': {
      width: 140
    },

    '& p': {
      padding: '20px 0'
    }
  }
}));

export const Taps = withRouter(({ history }) => {
  const { t } = useTranslation();
  let session = useContext(SessionContext);
  const { currentLocation, premium } = session;
  const [showPremiumFeatureDialog, setShowPremiumFeatureDialog] = useState(false);
  const [premiumFeatureName, setPremiumFeatureName] = useState('');
  const [showPrint, setShowPrint] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const edit = (tap) => {
    if (tap) history.push(`/taps/${tap._id}/edit`);
    else history.push(`/taps/new`);
  };

  const handlePrint = () => {
    if (premium) {
      setShowPrint(true);
    } else {
      setPremiumFeatureName(t('PremiumFeatureDialog.Features.PrintedMenus'));
      setShowPremiumFeatureDialog(true);
    }
  };

  const handleEmbed = () => {
    if (premium) {
      setShowEmbed(true);
    } else {
      setPremiumFeatureName(t('PremiumFeatureDialog.Features.WebsiteEmbed'));
      setShowPremiumFeatureDialog(true);
    }
  };

  const handleQrCode = (state) => () => {
    if (premium) {
      setShowQrCode(state);
    } else {
      setPremiumFeatureName(t('PremiumFeatureDialog.Features.QRCodeMenus'));
      setShowPremiumFeatureDialog(true);
    }
  };

  const classes = useStyles();

  const { data, loading, error } = useQuery(GET_TAPS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network',
    skip: !currentLocation
  });

  const taps = sortBy(data?.taps || [], (t) => t.name);

  const filteredTaps = !searchQuery ? taps : taps.filter((tap) => tap.beverage?.name?.toLowerCase().includes(searchQuery.toLowerCase()));
  const { activeTaps, onDeckTaps, inactiveTaps } = filteredTaps.reduce(
    (acc, t) => {
      const statusKey = `${camelCase(t.status)}Taps`;
      acc[statusKey] = [...acc[statusKey], t];
      return acc;
    },
    { activeTaps: [], onDeckTaps: [], inactiveTaps: [] }
  );

  return (
    <UserVesselsProvider>
      <Layout title={t('Taps.TapMenu')} className="font-public-sans border !border-gray-200 !pt-0">
        <FeedbackBar message={error} />
        <PrintDialog open={showPrint} tapsCount={taps.length} onCancel={() => setShowPrint(false)} />
        <EmbedDialog open={showEmbed} tapsCount={taps.length} onCancel={() => setShowEmbed(false)} />
        <QrCodeDialog open={showQrCode} onCancel={handleQrCode(false)} />

        <div className="flex px-4 gap-2 py-3 border-b border-gray-200 sticky top-0 bg-white z-20">
          <Button rounded onClick={() => edit()}>
            <Add className="w-4 h-4" /> {t('Taps.NewTap')}
          </Button>

          <SearchInput value={searchQuery} onChange={setSearchQuery} placeholder={''} />

          <div className="flex gap-2 ml-auto">
            <Hidden xsDown>
              <Tooltip text="Print Menu">
                <button
                  className={`relative w-[56px] h-[36px] rounded-full border ${
                    premium ? 'text-dark-green border-green hover:bg-light-green' : 'text-light-grey border-accent-grey'
                  }`}
                  onClick={handlePrint}>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <PrintIcon className="w-5 h-5" />
                  </div>
                  {!premium && (
                    <img
                      alt="Icon Premium Feature"
                      src={IconPremiumChip}
                      className="absolute top-[-7px] right-[-7px] rotate-[30deg] w-5 h-5"
                    />
                  )}
                </button>
              </Tooltip>

              <Tooltip text="QR Code">
                <button
                  className={`relative w-[56px] h-[36px] rounded-full border ${
                    premium ? 'text-dark-green border-green hover:bg-light-green' : 'text-light-grey border-accent-grey'
                  }`}
                  onClick={handleQrCode(true)}>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <QrCodeIcon className="w-4 h-4" />
                  </div>
                  {!premium && (
                    <img
                      alt="Icon Premium Feature"
                      src={IconPremiumChip}
                      className="absolute top-[-7px] right-[-7px] rotate-[30deg] w-5 h-5"
                    />
                  )}
                </button>
              </Tooltip>

              <Tooltip text="Embed">
                <button
                  className={`relative w-[56px] h-[36px] rounded-full border ${
                    premium ? 'text-dark-green border-green hover:bg-light-green' : 'text-light-grey border-accent-grey'
                  }`}
                  onClick={handleEmbed}>
                  <div className="absolute inset-0 flex items-center justify-center">
                    <EmbedIcon className="w-6 h-6" />
                  </div>
                  {!premium && (
                    <img
                      alt="Icon Premium Feature"
                      src={IconPremiumChip}
                      className="absolute top-[-7px] right-[-7px] rotate-[30deg] w-5 h-5"
                    />
                  )}
                </button>
              </Tooltip>
            </Hidden>
          </div>
        </div>
        {loading && <Loading style={{ margin: 40 }} />}
        {!loading && taps.length === 0 && (
          <div className="w-full flex flex-col items-center">
            <div className={clsx(classes.emptyListContainer, 'flex max-w-lg flex-col items-center gap-8')}>
              <h2 className="text-xl font-bold text-dark-grey">{t('Taps.NoTapsYet')}</h2>
              <img alt="No Taps" src={EmptyTapListImage} />
              <p className="!py-0 text-md">
                {t('Taps.TapsAreBuildingBlocks')} <CustomLink href="/screens">{t('Taps.Screens')}</CustomLink>.
              </p>
              <Button variant="primary" onClick={() => edit()}>
                {t('Taps.AddYourFirstTap')}
              </Button>
            </div>
          </div>
        )}
        {!loading && taps.length > 0 && (
          <TapList activeTaps={activeTaps} onDeckTaps={onDeckTaps} inactiveTaps={inactiveTaps} onEdit={edit} />
        )}
      </Layout>
      <PremiumFeatureDialog
        show={showPremiumFeatureDialog}
        featureName={premiumFeatureName}
        onClose={() => setShowPremiumFeatureDialog(false)}
      />
    </UserVesselsProvider>
  );
});
