import { useTranslation } from 'react-i18next';
import Dense from '../../../../../images/ph-dense.png';
import MiddleGround from '../../../../../images/ph-middleground.png';
import Relaxed from '../../../../../images/ph-relaxed.png';
import SingleColumn from '../../../../../images/ph-single.png';
import TwoColumns from '../../../../../images/ph-2col.png';
import ThreeColumns from '../../../../../images/ph-3col.png';
import LTR from '../../../../../images/ph-ltr.png';
import TTB from '../../../../../images/ph-ttb.png';

export const SizeHelper = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-12">
      {/* Layout Density */}
      <LayoutDensity t={t} />
      <hr className="w-full  bg-accent-grey" />
      {/* Column Layout */}
      <ColumnLayout t={t} />
      <hr className="w-full  bg-accent-grey" />
      {/* Tap Orientation */}
      <TapOrientation t={t} />
    </div>
  );
};

const LayoutDensity = ({ t }: any) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-2xl font-semmibold text-dark-grey leading-[normal]">{t('PopupHelpers.LayoutDensity')}</h2>
      <div className="flex gap-6 flex-wrap">
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.Dense') as string}
          <img src={Dense} alt={`${t('PopupHelpers.Dense')} ${t('PopupHelpers.LayoutDensity')}`} />
        </div>
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.MiddleGround') as string}
          <img src={MiddleGround} alt={`${t('PopupHelpers.MiddleGround')} ${t('PopupHelpers.LayoutDensity')}`} />
        </div>
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.Relaxed') as string}
          <img src={Relaxed} alt={`${t('PopupHelpers.Relaxed')} ${t('PopupHelpers.LayoutDensity')}`} />
        </div>
      </div>
    </div>
  );
};
const ColumnLayout = ({ t }: any) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-2xl font-semmibold text-dark-grey leading-[normal]">{t('PopupHelpers.ColumnLayout')}</h2>
      <div className="flex gap-6 flex-wrap">
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.SingleColumn') as string}
          <img src={SingleColumn} alt={`${t('PopupHelpers.SingleColumn')} ${t('PopupHelpers.ColumnLayout')}`} />
        </div>
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.TwoColumns') as string}
          <img src={TwoColumns} alt={`${t('PopupHelpers.TwoColumns')} ${t('PopupHelpers.ColumnLayout')}`} />
        </div>
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.ThreeColumns') as string}
          <img src={ThreeColumns} alt={`${t('PopupHelpers.ThreeColumns')} ${t('PopupHelpers.ColumnLayout')}`} />
        </div>
      </div>
    </div>
  );
};
const TapOrientation = ({ t }: any) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="text-2xl font-semmibold text-dark-grey leading-[normal]">{t('PopupHelpers.TapOrientation')}</h2>
      <div className="flex gap-6 flex-wrap">
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.LTR') as string}
          <img src={LTR} alt={`${t('PopupHelpers.LTR')} ${t('PopupHelpers.TapOrientation')}`} />
        </div>
        <div className="flex flex-col w-[280px] gap-4 text-xl text-dark-grey font-normal leading-[normal]">
          {t('PopupHelpers.TTB') as string}
          <img src={TTB} alt={`${t('PopupHelpers.TTB')} ${t('PopupHelpers.TapOrientation')}`} />
        </div>
      </div>
    </div>
  );
};
