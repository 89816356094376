import { useState } from 'react';
import { useMutation } from 'react-apollo';
import { SIGN_FILE_FOR_UPLOAD } from '../screens/screens/mutations';
import { checkFileSize } from '../util/files';

interface UseFileUploadOptions {
  maxFileSize: number;
  locationName: string;
}

interface UseFileUploadReturn {
  file: File | null;
  previewUrl: string | undefined;
  error: Error | null;
  signedAwsUrl: string | null;
  resourceUrl: string | null;
  handleFileSelection: (file: File, previewUrl: string) => Promise<void>;
  reset: () => void;
}

export const useFileUpload = ({ maxFileSize, locationName }: UseFileUploadOptions): UseFileUploadReturn => {
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string>();
  const [error, setError] = useState<Error | null>(null);
  const [signedAwsUrl, setSignedAwsUrl] = useState<string | null>(null);
  const [resourceUrl, setResourceUrl] = useState<string | null>(null);

  const [signFile] = useMutation(SIGN_FILE_FOR_UPLOAD);

  const handleFileSelection = async (file: File, previewUrl: string) => {
    setError(null);
    const fileSizeError = checkFileSize(file, maxFileSize);

    if (fileSizeError) {
      setError(fileSizeError);
      return;
    }

    setPreviewUrl(previewUrl);

    const variables = {
      locationName,
      fileName: file.name,
      fileType: file.type
    };

    try {
      const { data, errors } = await signFile({ variables });

      if (errors) {
        setError(new Error(errors[0].message));
        return;
      }

      if (data?.signFile) {
        setFile(file);
        setSignedAwsUrl(data.signFile.signedUrl);
        setResourceUrl(data.signFile.resourceUrl);
      }
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Unknown error occurred'));
      console.warn(err);
    }
  };

  const reset = () => {
    setFile(null);
    setPreviewUrl(undefined);
    setError(null);
    setSignedAwsUrl(null);
    setResourceUrl(null);
  };

  return {
    file,
    previewUrl,
    error,
    signedAwsUrl,
    resourceUrl,
    handleFileSelection,
    reset
  };
};
