import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const PageCard = ({ page, screenId }) => {
  const { t } = useTranslation();

  return (
    <Link
      to={`/screens/${screenId}/pages/${page.key}/edit`}
      className="my-2 cursor-pointer border rounded border-light-grey hover:border-blackish">
      <div className="p-4">
        <div className="flex justify-start">
          <div className="mr-4">
            <span className="inline-block border border-accent-gray rounded-full h-10 w-10 leading-[38px] text-center text-sm mt-1">
              {page.number || '?'}
            </span>
          </div>

          <div>
            <div className="font-bold text-xl leading-[1.45] mb-1.5">{page.name}</div>

            {page.type === 'taps' && (
              <div>
                <span className="text-lg leading-[1.2]">
                  {t('PageCard.Taps')} ({page.tapStart || 1} - {page.tapEnd})
                </span>
              </div>
            )}

            {page.type === 'image' && (
              <div>
                <span className="text-lg leading-[1.2]">{t('PageCard.CustomImage')}</span>
              </div>
            )}

            <div className="text-lg leading-[1.2] text-light-blackish mt-1">
              {page.rotationDuration} {t('PageCard.Seconds')}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};
