import React from 'react';
import { useQuery } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Layout } from '../../components/Layout';
import { ScreenEditForm } from './ScreenEditForm';
import { FeedbackBar } from '../../components/FeedbackBar';
import { Loading } from '../../components/Loading';

import { GET_SCREEN_BY_ID } from './queries';

export const EditScreen = withRouter(({ match, history }) => {
  const { t } = useTranslation();
  const screenId = match?.params?.screen_id;

  const { data, loading, error } = useQuery(GET_SCREEN_BY_ID, {
    variables: { id: screenId }
  });

  let screen = data?.screen;

  return (
    <Layout title={t('EditScreen.EditScreen')} className="!pt-0 font-public-sans">
      <FeedbackBar message={error} />
      {loading && <Loading style={{ margin: 40 }} />}
      {!loading && <ScreenEditForm screen={screen} onClose={() => history.push('/screens')} />}
    </Layout>
  );
});
