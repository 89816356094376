export interface TabProps {
  children: React.ReactNode;
  label: string;
  isActive?: boolean;
  className?: string;
}

export const Tab: React.FC<TabProps> = ({ children, isActive, className }) => {
  return (
    <div
      role="tabpanel"
      className={`
        ${!isActive ? 'hidden' : 'animate-fadeIn'}
        ${className}
      `}>
      {children}
    </div>
  );
};
