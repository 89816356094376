import { filter } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from '../../../../components/shared/Card';

interface PageStyleThemeProps {
  screen: any;
  page: any;
  stylePageKey: string;
  setStylePageKey: (key: string) => void;
}

export const PageStyleCopyFrom = ({ screen, page, stylePageKey, setStylePageKey }: PageStyleThemeProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-4">
      {filter(screen.pages, (p) => p.key !== page.key).length === 0 ? (
        <div className="flex flex-col items-center gap-4">
          <h3 className="text-xl text-center font-bold text-dark-grey">{t('PageEditForm.NoPages')}</h3>
          <p className="text-md text-blackish text-center">{t('PageEditForm.NoPagesMessage')}</p>
        </div>
      ) : (
        <p className="text-md text-blackish">{t('PageEditForm.CopyPageTitle')}</p>
      )}
      <div className="flex flex-wrap gap-4">
        {filter(screen.pages, (p) => p.key !== page.key).map((p) => (
          <Card
            variant="outlined"
            selected={stylePageKey === p.key}
            className="flex flex-col items-center justify-center text-center cursor-pointer w-[106px] h-[69px]"
            onClick={() => setStylePageKey(p.key)}
            key={p.key}>
            <Card.Content className="flex flex-col items-center">
              <p className="text-lg text-dark-grey">Page {p.number}</p>
            </Card.Content>
          </Card>
        ))}
      </div>
    </div>
  );
};
