import { useState, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { SessionContext } from '../../contexts/session';
import { UserVesselsContext } from '../../contexts/userVessels';
import { DELETE_USER_VESSEL } from '../../screens/userVessels/mutations';
import { resolveVesselSize, VESSELS } from '../../util/lang';
import { Serving, Vessel } from '../../types';

export const useEditServings = (serving: Serving, onSave: (serving: Serving) => void) => {
  const { currentLocation } = useContext(SessionContext);
  const { userVessels } = useContext(UserVesselsContext) as any;

  const [vessel, setVessel] = useState<Vessel>(
    serving.vessel
      ? { name: serving.vessel, size: serving.size, _id: '', createdBy: '', isNew: false }
      : { _id: '', name: '', size: 0, createdBy: '', isNew: false }
  );
  const [size, setSize] = useState<number | ''>(serving.size || '');
  const [price, setPrice] = useState<number | ''>(serving.price || '');
  const [currency, setCurrency] = useState<string>(serving?.settings?.currency || currentLocation?.servingSettings?.currency);
  const [volumeUnit, setVolumeUnit] = useState<string>(serving?.settings?.volumeUnit || currentLocation?.servingSettings?.volumeUnit);
  const [validating, setValidating] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [vesselToDelete, setVesselToDelete] = useState<Vessel | null>(null);

  const [doDeleteUserVessel] = useMutation(DELETE_USER_VESSEL);

  const handleSave = async () => {
    setValidating(true);
    if (vessel && vessel.name && size && price != null && price !== '') {
      let updatedItem: Serving = {
        ...serving,
        vessel: vessel.name,
        isNew: !!vessel.isNew,
        size: Number(size),
        price: Number(price),
        settings: { volumeUnit, currency }
      };
      onSave?.(updatedItem);
      return updatedItem;
    }
  };

  const selectVessel = (vessel: Vessel) => {
    setVessel(vessel);
    if (vessel.size) {
      setSize(resolveVesselSize(vessel, volumeUnit) as number);
    }
  };

  const selectVolumeUnit = (volumeUnit: string) => {
    setVolumeUnit(volumeUnit);
    setSize(resolveVesselSize(vessel, volumeUnit));
  };

  const options = VESSELS.concat(
    userVessels?.map(({ _id, name, size }: Vessel) => {
      return {
        _id,
        name,
        size,
        type: 'Custom'
      };
    })
  );

  const onCancelDelete = () => {
    setDeleteDialogOpen(false);
    setVesselToDelete(null);
  };

  const onDeleteVessel = async () => {
    if (vesselToDelete) {
      try {
        await doDeleteUserVessel({
          variables: { id: vesselToDelete._id }
        });
        options.splice(options.indexOf(vesselToDelete), 1);
      } catch (error) {
        console.error('Failed to delete vessel:', error);
      }
    }
    onCancelDelete();
  };

  const handlePriceChange = (value: string | number | null) => {
    if (value !== null) {
      setPrice(Number(value));
    }
  };

  return {
    vessel,
    setVessel,
    size,
    price,
    currency,
    volumeUnit,
    validating,
    deleteDialogOpen,
    handleSave,
    selectVessel,
    selectVolumeUnit,
    options,
    onCancelDelete,
    onDeleteVessel,
    handlePriceChange,
    setDeleteDialogOpen,
    setVesselToDelete,
    setSize,
    setCurrency
  };
};
