import React from 'react';
import { Card } from '../../../../components/shared/Card';
import { ThemeTile } from '../ThemeTile';

interface PageStyleThemeProps {
  Themes: any[];
  styleThemeName: string;
  selectTheme: (theme: any) => void;
}

export const PageStyleTheme = ({ Themes, styleThemeName, selectTheme }: PageStyleThemeProps) => {
  return (
    <div className="flex flex-wrap gap-4">
      {Themes.map((t) => (
        <Card
          variant="outlined"
          key={t.name}
          selected={styleThemeName === t.name}
          onClick={() => selectTheme(t)}
          className="w-[205px] h-[112px]">
          <ThemeTile theme={t} />
        </Card>
      ))}
    </div>
  );
};
