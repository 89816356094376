import React from 'react';
import { Layout } from '../../../components/Layout';
import { useTranslation } from 'react-i18next';

interface PageEditFormLayoutProps {
  children: React.ReactNode;
  isNew?: boolean;
}

export const PageEditFormLayout: React.FC<PageEditFormLayoutProps> = ({ children, isNew = false }) => {
  const { t } = useTranslation();

  return (
    <Layout title={isNew ? t('PageEditForm.NewPage') : t('PageEditForm.EditPage')} className="font-public-sans">
      {children}
    </Layout>
  );
};
