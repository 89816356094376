import React, { useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';

import { SessionContext } from '../../contexts/session';

import { GET_SCREENS } from './queries';

import { Layout } from '../../components/Layout';
import { FeedbackBar } from '../../components/FeedbackBar';
import { ScreenEditForm } from './ScreenEditForm';

export const NewScreen = withRouter(({ history }) => {
  const { t } = useTranslation();
  let screen = { number: 1, name: 'Bar Screen 1', pages: [] };

  const { currentLocation } = useContext(SessionContext);

  const { loading, error, data } = useQuery(GET_SCREENS, {
    variables: { location: currentLocation?._id },
    fetchPolicy: 'cache-and-network'
  });

  // TODO not sure if this is really necessary, but the API requires each screen to have a number
  const screenNumbers = (data?.screens || []).map((s) => s.number);
  if (screenNumbers.length > 0) {
    let [min, max] = [Math.min(...screenNumbers), Math.max(...screenNumbers)];
    let out = Array.from(Array(max - min), (v, i) => i + min).filter((i) => !screenNumbers.includes(i));
    if (out.length > 0) screen.number = out[0];
    else screen.number = max + 1;

    screen.name = `Bar Screen ${screen.number}`;
  }

  return (
    <Layout title={t('NewScreen.NewScreen')} className="!pt-0 font-public-sans">
      <FeedbackBar message={error} />
      {!loading && <ScreenEditForm screen={screen} onClose={() => history.push('/screens')} />}
    </Layout>
  );
});
