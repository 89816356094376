import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import ChevronUp from '../../images/chevron-up';
import ChevronDown from '../../images/chevron-down';
import { createPortal } from 'react-dom';

interface InputOption {
  value: string | number;
  label: React.ReactNode;
  className?: string;
}

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  label?: string;
  error?: string | boolean;
  options?: InputOption[];
  select?: boolean;
  onOptionSelect?: (value: string | number) => void;
  value?: string | number | null;
  onChange?: (value: string | number | null) => void;
  searchable?: boolean;
  multiline?: boolean;
  rows?: number;
  step?: number;
  numberMode?: 'float' | 'integer';
  containerClassName?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  precision?: number;
}

const getTextAreaRows = (value: string, minRows: number = 2): number => {
  if (!value) return minRows;
  const lines = value.split('\n').length;
  return Math.max(minRows, lines);
};

export const Input: React.FC<InputProps> = ({
  label,
  error,
  options = [],
  value = '',
  onChange,
  onOptionSelect,
  searchable = false,
  type = 'text',
  className = '',
  containerClassName = '',
  multiline = false,
  rows = undefined,
  step = 1,
  numberMode = 'integer',
  startIcon,
  endIcon,
  precision = 2,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [rawInput, setRawInput] = useState<string>('');
  const inputRef = useRef<HTMLInputElement & HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isSelect = !!onOptionSelect;
  const allowCustomInput = !!onChange;
  const isSearchableSelect = isSelect && searchable && !allowCustomInput;

  const closeDropdown = useCallback(() => {
    setShowOptions(false);
    setSearchText('');
  }, []);

  const openDropdown = useCallback(() => {
    if (options?.length > 0 && !props.disabled) {
      setShowOptions(true);
    }
  }, [options?.length, props.disabled]);

  const toggleDropdown = useCallback(() => {
    if (showOptions) {
      closeDropdown();
    } else {
      openDropdown();
    }
  }, [showOptions, closeDropdown, openDropdown]);

  // Click outside handler
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node) && !dropdownRef.current?.contains(event.target as Node)) {
        closeDropdown();
      }
    };

    if (showOptions) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [showOptions, closeDropdown]);

  const handleInputClick = (e: React.MouseEvent) => {
    if (!isSelect && !searchable) return;
    e.stopPropagation();
    openDropdown();
  };

  const handleOptionClick = useCallback(
    (option: InputOption) => {
      if (option && option.label !== 'No Options') {
        const optionValue = type === 'number' ? (Number.isNaN(Number(option.value)) ? 0 : Number(option.value)) : option.value;

        if (isSelect || isSearchableSelect) {
          onOptionSelect?.(optionValue);
        }

        if (allowCustomInput) {
          onChange?.(optionValue);
        }

        closeDropdown();
      }
    },
    [type, isSelect, isSearchableSelect, allowCustomInput, onChange, onOptionSelect, closeDropdown]
  );

  const handleFocusChange = useCallback(
    (isFocusing: boolean) => {
      setIsFocused(isFocusing);
      if (!isFocusing) {
        // Small delay to allow click events to process first
        setTimeout(closeDropdown, 150);
      }
    },
    [closeDropdown]
  );

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const inputValue = e.target.value;

      if (isSearchableSelect) {
        setSearchText(inputValue);
        openDropdown();
      } else if (allowCustomInput) {
        if (type === 'number') {
          if (numberMode === 'float' && !isNaN(parseFloat(inputValue))) {
            if (inputValue === '' || !isNaN(Number(inputValue))) {
              setRawInput(inputValue);
              const num = inputValue === '' ? null : Number(inputValue);
              onChange?.(num);
            }
          } else if (numberMode === 'integer' && !isNaN(parseInt(inputValue))) {
            onChange?.(inputValue === '' ? null : Math.round(Number(inputValue)));
          }
        } else {
          onChange?.(inputValue);
        }
        openDropdown();
      }
    },
    [isSearchableSelect, allowCustomInput, type, numberMode, onChange, openDropdown]
  );

  const hasValue = value !== undefined && value !== '';
  const showLabel = isFocused || hasValue;

  const displayValue = useMemo(() => {
    if (value === null || value === undefined) return '';

    if (isSearchableSelect && isFocused && showOptions) {
      return searchText;
    }

    if ((isSelect && !allowCustomInput) || isSearchableSelect) {
      const selectedOption = options.find((opt) => {
        const optVal = JSON.stringify(opt.value);
        const compareVal = JSON.stringify(value);
        return optVal === compareVal;
      });

      if (!selectedOption) {
        console.warn('No matching option found for value:', value, 'Available options:', options);
      }

      return selectedOption?.label?.toString() ?? '';
    }

    if (type === 'number' && numberMode === 'float') {
      return rawInput || (typeof value === 'number' ? value.toFixed(precision) : value.toString());
    }

    return value.toString();
  }, [
    value,
    options,
    isSelect,
    allowCustomInput,
    isSearchableSelect,
    isFocused,
    searchText,
    showOptions,
    type,
    numberMode,
    rawInput,
    precision
  ]);

  const filteredOptions = useMemo(() => {
    if (!showOptions) return [];
    if (isSelect && !allowCustomInput && !isSearchableSelect) return options;

    const searchValue = (isSearchableSelect ? searchText : value ?? '').toString().toLowerCase();
    if (!searchValue && !isSearchableSelect) return options;
    const exactMatch = options.some((option) => {
      const optionLabel = (typeof option === 'string' ? option : option?.label)?.toString()?.toLowerCase();
      return optionLabel === searchValue;
    });
    if (exactMatch) return options;

    const filtered = options.filter((option) => {
      const optionStr = (typeof option === 'string' ? option : `${option.label} ${option.value}`).toLowerCase();
      return optionStr.includes(searchValue);
    });

    return filtered.length > 0 ? filtered : [{ value: '', label: 'No Options' }];
  }, [options, value, showOptions, isSelect, allowCustomInput, isSearchableSelect, searchText]);

  const handleNumberChange = useCallback(
    (increment: boolean) => {
      const currentValue = Number(value ?? 0);
      if (isNaN(currentValue)) return;

      const newValue = increment ? currentValue + step : currentValue - step;

      if (numberMode === 'float') {
        const fixedValue = Number(newValue.toFixed(precision));
        setRawInput(fixedValue.toFixed(precision));
        onChange?.(fixedValue);
      } else {
        onChange?.(Math.round(newValue));
      }
    },
    [value, step, numberMode, onChange, precision]
  );

  const handleNumberIncrease = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleNumberChange(true);
  };

  const handleNumberDecrease = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    handleNumberChange(false);
  };

  const handleBlur = (e: React.FocusEvent) => {
    requestAnimationFrame(() => {
      const activeElement = document.activeElement;
      const dropdownElement = dropdownRef.current;
      const inputElement = inputRef.current;

      // Only close if the new focus is not within our dropdown or input
      if (dropdownElement && inputElement && !dropdownElement.contains(activeElement) && !inputElement.contains(activeElement)) {
        setShowOptions(false);
        setIsFocused(false);
      }
    });
  };

  const isTextArea = multiline && !isSelect && allowCustomInput;

  const calculatedRows = useMemo(() => {
    if (rows !== undefined) return rows;
    return getTextAreaRows(value?.toString() ?? '', 2);
  }, [rows, value]);

  const isError = !!error;

  const inputClassName = `
    peer w-full px-4 py-3
    border rounded-[4px] outline-none box-shadow-none text-base leading-6
    placeholder-transparent resize-none
    ${
      isError
        ? 'text-red'
        : !props.disabled
        ? 'text-blackish border-light-grey hover:border-dark-grey'
        : 'text-light-grey border-accent-grey'
    }
    ${isError ? 'border-red focus:border-red' : !!isFocused && 'border-hover-green focus:border-hover-green'}
    transition-all duration-200 ease-in-out
    ${className}
  `;

  const labelClassName = `
    absolute left-3 px-1
    pointer-events-none
    text-light-blackish
    whitespace-nowrap
    bg-white
    transform
    -translate-y-1/2
    transition-all duration-200 ease-in-out
    peer-placeholder-shown:text-base
    peer-placeholder-shown:top-1/2
    peer-placeholder-shown:translate-y-1/2
    peer-placeholder-shown:px-0
    peer-placeholder-shown:left-4
    peer-focus:top-0
    peer-focus:left-3
    peer-focus:px-1
    peer-focus:text-sm
    ${showLabel ? 'top-0 text-sm' : 'top-1/2 text-base'}
    ${isError && 'text-red peer-focus:text-red'}
  `;

  return (
    <div ref={containerRef} className={`relative w-full ${containerClassName}`} onBlur={handleBlur}>
      <div className="relative" onClick={handleInputClick}>
        {isTextArea ? (
          <textarea
            ref={inputRef}
            value={displayValue}
            onChange={handleInputChange}
            onFocus={() => handleFocusChange(true)}
            placeholder={!showLabel ? label : ''}
            rows={calculatedRows}
            className={inputClassName}
            {...(props as React.TextareaHTMLAttributes<HTMLTextAreaElement>)}
          />
        ) : (
          <div className="relative flex items-center">
            {startIcon && <div className="absolute left-4">{startIcon}</div>}
            <input
              ref={inputRef}
              type={type === 'number' ? 'text' : type}
              value={displayValue}
              onChange={handleInputChange}
              onFocus={() => handleFocusChange(true)}
              placeholder={!showLabel ? label : ''}
              readOnly={isSelect && !allowCustomInput && !isSearchableSelect}
              className={`
                ${inputClassName}
                h-[56px]
                ${isSelect || isSearchableSelect ? 'cursor-pointer bg-white' : ''}
                ${startIcon ? 'pl-12' : ''}
                ${endIcon ? 'pr-12' : ''}
              `}
              {...props}
            />
            {endIcon && <div className="absolute right-4 flex items-center">{endIcon}</div>}
          </div>
        )}

        {!isTextArea && (isSelect || options?.length > 0 || (type === 'number' && !props.disabled)) && (
          <div className={`absolute right-4 top-1/2 -translate-y-1/2 flex items-center gap-2 ${!!isSelect && 'pointer-events-none'}`}>
            {type === 'number' && !props.disabled ? (
              <div className="flex flex-col gap-0.5">
                <button type="button" className="p-0.5 bg-transparent rounded cursor-pointer" onClick={handleNumberIncrease} tabIndex={-1}>
                  <ChevronUp className={`w-3 h-3 ${!props.disabled ? 'text-dark-grey' : 'text-light-grey'}`} />
                </button>
                <button type="button" className="p-0.5 bg-transparent rounded cursor-pointer" onClick={handleNumberDecrease} tabIndex={-1}>
                  <ChevronDown className={`w-3 h-3 ${!props.disabled ? 'text-dark-grey' : 'text-light-grey'}`} />
                </button>
              </div>
            ) : (
              <span
                onClick={toggleDropdown}
                className={`
                  w-4 h-4 ${!props.disabled ? 'text-dark-grey' : 'text-light-grey'} 
                  transition-transform duration-200 
                  inline-block cursor-pointer
                  ${showOptions ? 'rotate-180' : ''}
                `}>
                <ChevronDown className="w-4 h-4" />
              </span>
            )}
            {endIcon && <div className="flex items-center">{endIcon}</div>}
          </div>
        )}

        {label && <label className={labelClassName}>{label}</label>}

        {typeof error === 'string' && <p className="mt-1 text-xs text-red-500">{error}</p>}

        {options.length > 0 &&
          inputRef.current &&
          createPortal(
            <div
              ref={dropdownRef}
              style={{
                top: inputRef.current?.getBoundingClientRect().bottom + window.scrollY,
                left: inputRef.current?.getBoundingClientRect().left + window.scrollX,
                width: inputRef.current?.offsetWidth
              }}
              className={`bg-white border absolute z-[9999] border-light-grey rounded text-blackish overflow-auto transition-opacity duration-500 ${
                showOptions ? 'opacity-100 visible max-h-60 w-full' : 'opacity-0 invisible h-0 w-0'
              }`}>
              {filteredOptions.map((option, index) => (
                <div
                  key={index}
                  data-value={option.value}
                  className={`px-4 py-4 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ${
                    option.value === value ? 'bg-accent-grey' : ''
                  } ${option.label === 'No Options' ? 'cursor-default text-light-blackish' : ''} ${option.className ?? 'font-public-sans'}`}
                  onMouseDown={() => option.label !== 'No Options' && handleOptionClick(option)}>
                  {option.label}
                </div>
              ))}
            </div>,
            document.body
          )}
      </div>
    </div>
  );
};
