import React from 'react';
import clsx from 'clsx';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'outlined' | 'default';
  selected?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Card = ({ variant = 'default', selected = false, className, children, ...props }: CardProps) => {
  return (
    <div className="relative">
      <div
        className={clsx(
          'rounded-lg overflow-hidden relative',
          {
            'before:absolute before:inset-0 before:rounded-lg before:box-content before:pointer-events-none': variant === 'outlined',
            'before:border-1 before:border-accent-grey hover:before:border-hover-green hover:before:border-2':
              variant === 'outlined' && !selected,
            'before:border-hover-green before:border-2': selected,
            'bg-white': variant === 'default'
          },
          className
        )}
        {...props}>
        {children}
      </div>
      {selected && (
        <div className="absolute -top-2 -right-[10px] w-6 h-6 bg-hover-green rounded-full flex items-center justify-center">
          <svg width="10" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13 1L4.75 9.25L1 5.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      )}
    </div>
  );
};

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

Card.Content = function CardContent({ className, children, ...props }: CardContentProps) {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};
