import React, { CSSProperties, ReactNode } from 'react';
import clsx from 'clsx';

import { CloseIcon } from '../../images/CloseIcon';
import { useTranslation } from 'react-i18next';

interface AlertProps {
  severity?: 'error' | 'warning' | 'info' | 'success';
  variant?: 'standard' | 'filled' | 'outlined';
  onClose?: () => void;
  className?: string;
  children: ReactNode;
  style?: CSSProperties;
  icon?: ReactNode;
}

export const Alert = ({ severity = 'info', variant = 'standard', onClose, className, children, style, icon, ...props }: AlertProps) => {
  const { t } = useTranslation();

  const baseStyles = 'w-full p-4 flex items-center justify-center text-md border border-accent-grey';

  const variantStyles = {
    standard: {
      info: 'bg-light-green text-dark-green',
      error: 'bg-light-orange text-dark-orange',
      warning: 'bg-light-orange text-dark-orange',
      success: 'bg-light-green text-dark-green'
    },
    filled: {
      info: 'bg-green text-white',
      error: 'bg-red text-white',
      warning: 'bg-orange text-white',
      success: 'bg-green text-white'
    },
    outlined: {
      info: 'border border-green text-green',
      error: 'border border-red text-red',
      warning: 'border border-orange text-orange',
      success: 'border border-green text-green'
    }
  };

  return (
    <div className={clsx(baseStyles, variantStyles[variant][severity], className)} style={style} role="alert" {...props}>
      {icon && <div className="mr-2 self-start flex-shrink-0 w-6 h-6">{icon}</div>}
      <div className="flex-1">{children}</div>
      {onClose && (
        <button onClick={onClose} className="ml-auto flex-shrink-0">
          <span className="sr-only">{t('Common.Close')}</span>
          <CloseIcon className="h-4 w-4" />
        </button>
      )}
    </div>
  );
};
