import React, { useRef } from 'react';

interface ColorPickerProps {
  value: string;
  onChange: (color: string) => void;
  label?: string;
  className?: string;
  containerClassName?: string;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  value,
  onChange,
  label = 'Background Color',
  className = '',
  containerClassName
}) => {
  const picker = useRef<HTMLInputElement>(null);
  return (
    <div className={`relative flex mt-3 justify-center items-center h-[100px] ${containerClassName}`}>
      <div
        className={`absolute bg-transparent flex items-center p-4 justify-center w-full h-full rounded border border-light-grey ${className}`}>
        <div style={{ backgroundColor: value }} className="w-full h-full border border-accent-grey bg-transparent" />
      </div>
      {label && <label className="absolute -top-3 px-1.5 py-[2px] left-2 text-xs font-medium text-light-text bg-white">{label}</label>}
      <input
        ref={picker}
        type="color"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
    </div>
  );
};
